import { match } from 'ts-pattern';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import { mapCategory } from '../utils/mapper';
export const getQuestBuffer = (questDetails) => {
    return {
        goalId: questDetails.goalId,
        name: questDetails.name,
        isMandatory: questDetails.isMandatory,
        badge: questDetails.badge,
        objectives: questDetails.objectives,
        startDate: questDetails.startDate,
        endDate: questDetails.endDate,
        status: questDetails.status,
        basedOnRevisionNumber: questDetails.revisionNumber,
        assignedTeamIds: questDetails.assignedTeamIds,
    };
};
export const getNewQuestState = (startDate) => {
    const assignedTeamIds = [];
    return {
        goalId: uuidv4(),
        createdAt: new Date(),
        revisionNumber: 0,
        name: '',
        isMandatory: false,
        badge: null,
        objectives: [],
        startDate,
        endDate: dayjs(startDate).add(3, 'month').toDate(),
        status: 'new',
        assignedTeamIds,
    };
};
export const getAvailableCategories = (slugs, metadata) => {
    const availableCategories = slugs.map((slug) => mapCategory(slug, metadata));
    availableCategories.sort((a, b) => {
        return a.mainName.localeCompare(b.mainName) || a.name.localeCompare(b.name);
    });
    return availableCategories;
};
export const isCleanBuffer = (buffer, lastSavedBuffer) => {
    const bufferKeys = Object.keys(buffer);
    return bufferKeys.every((bufferKey) => match(bufferKey)
        .with('goalId', 'basedOnRevisionNumber', 'name', 'badge', 'status', 'isMandatory', (key) => buffer[key] === lastSavedBuffer[key])
        .with('startDate', 'endDate', (key) => { var _a, _b; return ((_a = buffer[key]) === null || _a === void 0 ? void 0 : _a.getTime()) === ((_b = lastSavedBuffer[key]) === null || _b === void 0 ? void 0 : _b.getTime()); })
        .with('objectives', (key) => isCleanObjectivesBuffer(buffer[key], lastSavedBuffer[key]))
        .with('assignedTeamIds', (key) => isCleanTeamAssignmentBuffer(buffer[key], lastSavedBuffer[key]))
        .exhaustive());
};
const getNameErrors = (quest) => {
    var _a, _b;
    const nameError = ((_a = quest.name) === null || _a === void 0 ? void 0 : _a.length) < 3
        ? 'questsAdmin.validation.nameTooShort'
        : ((_b = quest.name) === null || _b === void 0 ? void 0 : _b.length) > 250
            ? 'questsAdmin.validation.nameTooLong'
            : null;
    return nameError ? [{ field: 'name', message: nameError }] : [];
};
const getObjectiveErrors = (quest) => {
    var _a;
    return !((_a = quest.objectives) === null || _a === void 0 ? void 0 : _a.length)
        ? [{ field: 'objectives', message: 'questsAdmin.validation.missingObjectives' }]
        : [];
};
const getEndTimeErrors = (quest, dateNowTimestamp) => {
    var _a, _b;
    const endDateTime = (_b = (_a = quest === null || quest === void 0 ? void 0 : quest.endDate) === null || _a === void 0 ? void 0 : _a.getTime) === null || _b === void 0 ? void 0 : _b.call(_a);
    const endDateError = !endDateTime
        ? 'questsAdmin.validation.invalidEndDate'
        : endDateTime < dateNowTimestamp && (quest.status === 'new' || quest.status === 'draft')
            ? 'questsAdmin.validation.invalidPastEndDate'
            : null;
    return endDateError ? [{ field: 'endDate', message: endDateError }] : [];
};
const getStartTimeErrors = (quest) => {
    var _a, _b, _c, _d;
    const startDateTime = (_b = (_a = quest === null || quest === void 0 ? void 0 : quest.startDate) === null || _a === void 0 ? void 0 : _a.getTime) === null || _b === void 0 ? void 0 : _b.call(_a);
    const endDateTime = (_d = (_c = quest === null || quest === void 0 ? void 0 : quest.endDate) === null || _c === void 0 ? void 0 : _c.getTime) === null || _d === void 0 ? void 0 : _d.call(_c);
    const startDateError = !startDateTime
        ? 'questsAdmin.validation.invalidStartDate'
        : endDateTime && endDateTime < startDateTime
            ? 'questsAdmin.validation.invalidDateOrder'
            : null;
    return startDateError ? [{ field: 'startDate', message: startDateError }] : [];
};
export const getQuestValidationErrors = (quest, dateNowTimestamp) => {
    return [
        ...getNameErrors(quest),
        ...getObjectiveErrors(quest),
        ...getEndTimeErrors(quest, dateNowTimestamp),
        ...getStartTimeErrors(quest),
    ];
};
export const isCleanObjectivesBuffer = (buffer, lastSavedBuffer) => {
    if (buffer.length !== lastSavedBuffer.length)
        return false;
    return buffer.every((objective, index) => isCleanObjectiveBuffer(objective, lastSavedBuffer[index]));
};
export const isCleanTeamAssignmentBuffer = (buffer, lastSavedBuffer) => {
    return buffer.join(',') === lastSavedBuffer.join(',');
};
export const getDaysDelta = (dateA, dateB, useCeil = false) => {
    if (!dateA || !dateB)
        return -1;
    const dateATimestamp = new Date(dateA).getTime();
    const dateBTimestamp = new Date(dateB).getTime();
    if (!dateATimestamp || !dateBTimestamp)
        return -1; // Can occur for invalid date selection via text input editing
    const delta = (dateATimestamp - dateBTimestamp) / 86400000;
    return useCeil ? Math.ceil(delta) : Math.round(delta);
};
export const getCustomVulnerabilityObjectives = (objectives) => {
    return objectives.filter((o) => o.kind === 'learning-outcome');
};
export const includesMostCommonVulnerabilitiesObjective = (objectives) => {
    return objectives.some((o) => o.kind === 'most-common-vulnerabilities');
};
export const includesPciDssObjective = (objectives) => {
    return objectives.some((o) => o.kind === 'pci-dss');
};
export const getCategorySlugsFromObjectives = (objectives) => {
    return objectives
        .map((o) => (o.kind === 'learning-outcome' ? `${o.rootCategoryId}.${o.categoryId}.${o.subcategoryId}` : ''))
        .filter((slug) => !!slug);
};
export const getInitialSnackbarState = () => ({
    message: '',
    severity: 'success',
    open: false,
    key: new Date().getTime(),
});
export const isCleanObjectiveBuffer = (buffer, lastSavedBuffer) => {
    if (buffer.kind === 'most-common-vulnerabilities' || lastSavedBuffer.kind === 'most-common-vulnerabilities') {
        return buffer.kind === lastSavedBuffer.kind;
    }
    if (buffer.kind === 'pci-dss' || lastSavedBuffer.kind === 'pci-dss') {
        return buffer.kind === lastSavedBuffer.kind;
    }
    const bufferKeys = Object.keys(buffer);
    return bufferKeys.every((bufferKey) => match(bufferKey)
        .with('kind', 'rootCategoryId', 'categoryId', 'subcategoryId', (key) => buffer[key] === lastSavedBuffer[key])
        .exhaustive());
};
export const getMuiDatePickerLanguageKey = (currentLanguage) => {
    if (typeof currentLanguage !== 'string')
        return 'en-gb';
    if (currentLanguage.startsWith('en'))
        return 'en-gb';
    return currentLanguage.split('-')[0] || 'en-gb';
};
export const getEditingDisabledTooltip = (editingDisabled, status, t, i18nFormat, skeletonMode) => {
    if (skeletonMode)
        return undefined;
    const translationStatus = 'questsAdmin.entities.quests.status.' + (status === 'active' ? 'published' : status);
    return editingDisabled
        ? i18nFormat(t('questsAdmin.components.organisms.questEditor.general.draftsOnlyEditEnabled', {
            status: t(translationStatus),
        }), 'sentenceCase')
        : undefined;
};
export const getObjectivesWithUpdatedCustomVulnerabilitySelection = (objectivesBefore, newCategorySlugSelection) => {
    const nonCustomVulnerabilitiesObjectives = objectivesBefore.filter((obj) => obj.kind !== 'learning-outcome');
    const newlySelectedCustomVulnerabilitiesObjectives = newCategorySlugSelection.map((categorySlug) => {
        const [rootCategoryId, categoryId, subcategoryId] = categorySlug.split('.');
        return {
            kind: 'learning-outcome',
            rootCategoryId: rootCategoryId,
            categoryId: categoryId,
            subcategoryId: subcategoryId,
        };
    });
    return [...nonCustomVulnerabilitiesObjectives, ...newlySelectedCustomVulnerabilitiesObjectives];
};
export const getObjectivesWithUpdatedMostCommonVulnerabilitiesSelection = (objectivesBefore, selected) => {
    const nonMostCommonObjectives = objectivesBefore.filter((objective) => objective.kind !== 'most-common-vulnerabilities');
    return selected
        ? [...nonMostCommonObjectives, { kind: 'most-common-vulnerabilities' }]
        : nonMostCommonObjectives;
};
export const getObjectivesWithUpdatedPciDssSelection = (objectivesBefore, selected) => {
    const nonPciDssObjectives = objectivesBefore.filter((objective) => objective.kind !== 'pci-dss');
    return selected ? [...nonPciDssObjectives, { kind: 'pci-dss' }] : nonPciDssObjectives;
};
export const getPciDssCategoryInformation = (metadata, pciStandardCategories, t) => {
    const pciCategoryInformation = [];
    pciStandardCategories.forEach((category) => {
        category.topics.forEach((topic) => {
            var _a, _b, _c;
            const [group, main, sub] = topic.vulnerabilityKey.split('.');
            const name = (_c = (_b = (_a = metadata['categories'][group]) === null || _a === void 0 ? void 0 : _a[main]) === null || _b === void 0 ? void 0 : _b.subitem[sub]) === null || _c === void 0 ? void 0 : _c.name;
            const categoryNameNumber = category.name.split(':')[0].split('.')[0].split(' ')[1];
            pciCategoryInformation.push({
                mainName: t('questsAdmin.components.organisms.questEditor.categorySelection.requirementNum', {
                    num: categoryNameNumber,
                }),
                id: topic.vulnerabilityKey,
                rootName: group,
                name,
            });
        });
    });
    return pciCategoryInformation;
};
