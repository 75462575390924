import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useRef } from 'react';
import { VideoPlayer, getLocalVideoPreferences, Skeleton, } from '@securecodewarrior/design-system-react';
import { Link } from '@securecodewarrior/design-system-react/lib/wanda';
import { usePlatformContext } from '../platformContext';
import { platformLocaleToBcp47 } from '../../utils/platformLocaleToBcp47';
import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FileCopyRounded as FileCopyIcon, Google as GoogleIcon, PictureAsPdfRounded as PictureAsPdfIcon, } from '@mui/icons-material';
import { Divider } from '../Divider';
import { visuallyHidden } from '@mui/utils';
const sendPlatformMessage = (event) => window.postMessage(event);
const onSubtitlesChanged = (subtitles) => sendPlatformMessage({
    event: 'profileChanged',
    changes: { subtitlesLanguage: subtitles },
});
const onPreferencesChanged = (preferences) => sendPlatformMessage({
    event: 'videoPreferencesChanged',
    preferences: preferences,
});
const sendChurnzeroVideoStarted = () => {
    try {
        window.ChurnZeroEvent({ eventName: 'Learning Resource Accessed User' });
    }
    catch (error) {
        console.log(error);
    }
};
export const VideoPlayerBase = ({ video, analyticsScope, onComplete }) => {
    var _a, _b, _c;
    const { t } = useTranslation();
    const analyticsEventsSentVideoId = useRef({ play: '', complete: '' });
    const platformCtx = usePlatformContext();
    const initialSettings = Object.assign(Object.assign({}, getLocalVideoPreferences()), { subtitle: platformLocaleToBcp47((_c = (_b = (_a = platformCtx.sessionUser) === null || _a === void 0 ? void 0 : _a.properties) === null || _b === void 0 ? void 0 : _b.profile) === null || _c === void 0 ? void 0 : _c.subtitlesLanguage, null) });
    const videoId = video === null || video === void 0 ? void 0 : video.contentId;
    const onVideoStarted = () => {
        if (videoId && analyticsEventsSentVideoId.current.play !== videoId) {
            analyticsEventsSentVideoId.current.play = videoId;
            platformCtx.logAnalyticsEvent({ event: 'Play Video', scope: analyticsScope, video_slug: videoId });
            sendChurnzeroVideoStarted();
        }
    };
    const onVideoProgress = (videoProgress) => {
        if (videoId && analyticsEventsSentVideoId.current.complete !== videoId && videoProgress.percentage >= 90) {
            analyticsEventsSentVideoId.current.complete = videoId;
            platformCtx.logAnalyticsEvent({ event: 'Finished Video', scope: analyticsScope, video_slug: videoId });
            onComplete === null || onComplete === void 0 ? void 0 : onComplete(videoProgress.duration);
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(Typography, { sx: visuallyHidden, component: "h1", children: video ? video.title : t('common.activityType.video') }), _jsxs(Box, { sx: (theme) => ({
                    padding: `${theme.spacing(7)} ${theme.spacing(10)}`,
                    width: '100%',
                    maxWidth: '1024px',
                    margin: '0 auto',
                    position: 'relative',
                    'video, .video': {
                        ':focus': {
                            outline: `2px solid ${theme.palette.clickable.default}`,
                            outlineOffset: '2px',
                        },
                        borderRadius: '12px',
                        border: `1px solid ${theme.palette.container.border.default}`,
                        /* all of this below is tested on firefox and chrome */
                        /* force block, otherwise there's some spacing at the bottom which is neither margin nor padding */
                        display: 'block',
                        /* aspect-ratio has multiple purposes:
                         * - it gives the skeleton for the video the correct height
                         * - it fixes a layout jump when starting the video in Chrome
                         *   (because the poster is 15.8625:9, and Chrome fits the element to the poster size as
                         *    long as the video hasn't been started. See also LX-199)
                         */
                        aspectRatio: '16/9',
                        /* having a border messes up the aspect-ratio:16/9 rule because it makes it 16+2px/9+2px
                         * fix this by using 100%-2px for width so the video itself without border is 16/9 */
                        width: 'calc(100% - 2px)',
                        /* but we still want it to take 100% width so change the box-sizing property */
                        boxSizing: 'content-box',
                        /* object-fit to make the 15.8625:9 poster fit the 16:9 element size (see also LX-199) */
                        objectFit: 'fill',
                        /* Skeleton has a transform so the skeleton is smaller than the content it skeletons for,
                         * but we don't want that for the video element */
                        transform: 'unset',
                    },
                }), children: [video ? (_jsx(VideoPlayer, { sources: [
                            { codec: 'video/webm', url: video.data.video_webm },
                            ...(video.data.video_mp4 ? [{ codec: 'video/mp4', url: video.data.video_mp4 }] : []),
                        ], onVideoProgress, onVideoStarted, onSubtitlesChanged, onPreferencesChanged, initialSettings })) : (_jsx(Skeleton, { className: "video" })), _jsxs(Box, { display: "flex", py: "8px", mt: "12px", gap: "8px", children: [_jsx(Typography, { sx: (theme) => ({ lineHeight: '30px', a: { color: theme.palette.text.soft, svg: { marginRight: '4px' } } }), display: "flex", flexGrow: "1", flexWrap: "wrap", gap: "12px", component: "div", children: video ? (_jsxs(_Fragment, { children: [video.data.slides_pdf && (_jsx(Link, { color: "soft", href: video.data.slides_pdf, target: "_blank", rel: "noopener noreferrer", children: _jsxs(Stack, { alignItems: "center", direction: "row", gap: 1, children: [_jsx(PictureAsPdfIcon, { fontSize: "small" }), t('pages.explore.video.viewPdf')] }) })), video.data.slides_gdocs && (_jsx(Link, { color: "soft", href: video.data.slides_gdocs, target: "_blank", rel: "noopener noreferrer", children: _jsxs(Stack, { alignItems: "center", direction: "row", gap: 1, children: [_jsx(GoogleIcon, { fontSize: "small" }), t('pages.explore.video.viewGoogleSlides')] }) })), video.data.slides_ppt && (_jsx(Link, { color: "soft", href: video.data.slides_ppt, target: "_blank", rel: "noopener noreferrer", children: _jsxs(Stack, { alignItems: "center", direction: "row", gap: 1, children: [_jsx(FileCopyIcon, { fontSize: "small" }), t('pages.explore.video.downloadPpt')] }) }))] })) : (_jsx(Skeleton, { sx: { width: '20%' } })) }), _jsx(Box, {})] }), _jsx(Divider, { sx: { marginBottom: '32px' } }), _jsx(Typography, { "aria-hidden": "true", component: "p", variant: "h5", my: "20px", children: video ? video.title : _jsx(Skeleton, { sx: { width: '33%' } }) }), _jsx(Typography, { variant: "body1", my: "20px", children: video ? (video.description) : (_jsxs(_Fragment, { children: [_jsx(Skeleton, { sx: { width: '60%' } }), _jsx(Skeleton, { sx: { width: '45%' } })] })) })] })] }));
};
