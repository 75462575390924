export const react_router_states = {
  communications: 'communications',
  configureLearningModules: 'admin/learning-modules',
  cybermon: 'cybermon',
  dashboard: 'dashboard',
  engagement: 'engagement',
  explore: 'explore',
  questsAdmin: 'admin/quests',
  learn: 'learn',
  multiassessments: 'assessments/multi',
  myQuests: 'my-quests',
  successHub: 'success-hub',
  unsubscribe: 'unsubscribe',
  userManagement: 'user-management',
};
