import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Container, Stack, Typography } from '@mui/material';
export const StickyHeaderSection = ({ title, icon, children, }) => {
    return (_jsxs(Stack, { component: "section", sx: { width: '100%' }, children: [_jsx(Stack, { alignItems: "center", sx: (theme) => ({
                    borderTop: `1px solid ${theme.palette.container.border.default}`,
                    borderBottom: `1px solid ${theme.palette.container.border.default}`,
                    backgroundColor: theme.palette.container.fill.card1,
                    top: 0,
                    position: 'sticky',
                    zIndex: 2,
                }), children: _jsxs(Stack, { direction: "row", sx: (theme) => ({ width: '100%', gap: 2, py: 3, px: 13, maxWidth: theme.typography.pxToRem(1200) }), children: [icon, _jsx(Typography, { variant: "body0", sx: (theme) => ({ color: theme.palette.badge.text }), children: title })] }) }), _jsx(Container, { maxWidth: "lg", children: children })] }));
};
