import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Add } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { Card, RadialChart } from '@securecodewarrior/design-system-react/lib/labs';
import { CardTemplate } from '../../../templates/CardTemplate';
import { useDashboardAnalytics } from '../../../hooks/useDashboardAnalytics';
import { useTranslation } from 'react-i18next';
import { Button } from '@securecodewarrior/design-system-react/lib/wanda';
import { QuestHeaderActions } from './QuestHeaderActions';
import { QuestSelectionModal } from './QuestSelectionModal';
import { useDashboard } from '../../../context/dashboardContext';
const createAGoalReportUrl = '#/admin/quests/create/';
const SELECTED_QUEST_KEY = 'dashboard-selected-quest';
export const LearningProgressArchived = ({ goals = [] }) => {
    const { t } = useTranslation('react_components', { keyPrefix: 'pages.administration.dashboard.learningProgress' });
    const { refreshCard } = useDashboard();
    const { emitEvent } = useDashboardAnalytics();
    const [modalOpen, setModalOpen] = useState(false);
    const clickCreateAGoal = (event) => {
        // Prevent the default link navigation
        event.preventDefault();
        emitEvent('clicked_create_a_goal_button');
        window.location.href = createAGoalReportUrl;
    };
    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };
    const handleSave = (goalId) => {
        const newSelectedGoal = goals.find((goal) => goal.goalId === goalId);
        if (newSelectedGoal) {
            localStorage.setItem(SELECTED_QUEST_KEY, goalId);
            refreshCard('learning-progress');
            // Fire analytics event
            emitEvent('changed_goal');
        }
        setModalOpen(false);
    };
    return (_jsxs(CardTemplate, { children: [_jsxs(CardTemplate.Header, { children: [_jsx(Typography, { variant: "h5", children: t('quest') }), _jsx(QuestHeaderActions, { onSelectGoal: toggleModal, enableViewProgressReport: false })] }), _jsx(QuestSelectionModal, { open: modalOpen, onClose: toggleModal, onSave: handleSave, goals: goals }), _jsxs(CardTemplate.Body, { children: [_jsxs(Stack, { direction: "column", alignItems: "center", justifyContent: "space-between", gap: 4, children: [_jsx(RadialChart, { data: { type: 'percentage', animate: true, name: 'value' }, dataKey: "value", domain: [0, 100] }), _jsx(Card, { level: 2, sx: { padding: (theme) => theme.spacing(2, 3), height: 38, width: 200 } })] }), _jsx(Card, { level: 2, padding: 4, display: "grid", sx: { placeItems: 'center' }, children: _jsxs(Stack, { alignItems: "center", justifyContent: "center", gap: 6, children: [_jsxs(Stack, { direction: "column", gap: 2, alignItems: "center", justifyContent: "center", children: [_jsx(Typography, { variant: "h6", textAlign: "center", children: t('setUpALearningQuest') }), _jsx(Typography, { textAlign: "center", children: t('description') })] }), _jsx(Button, { startIcon: _jsx(Add, {}), variant: "outlined", href: createAGoalReportUrl, onClick: clickCreateAGoal, children: t('createQuest') })] }) })] })] }));
};
