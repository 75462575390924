var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { gql, CombinedError } from 'urql';
import { NetworkError } from './errors';
const TRUST_SCORE_QUERY = gql `
  query companyTrustScore {
    trustScores {
      company {
        trustScore {
          trustScore
        }
        recommendations {
          recommendation
          ordinal
          creationDate
        }
      }
      industry(first: 10000) {
        edges {
          node {
            trustScore
          }
        }
        pageInfo {
          hasNextPage
          endCursor
          hasPreviousPage
        }
      }
    }
  }
`;
// Same logic as in the reporting frontend for consistency with reporting data
const getPercentile = (industryTrustScores = [], companyTrustScore = 0) => {
    const total = industryTrustScores.length;
    const index = companyTrustScore ? industryTrustScores.indexOf(companyTrustScore) : 0;
    return index + 1 !== total ? Math.floor((index / total) * 100) : 100;
};
export const fetchTrustScore = (client) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const { data, error } = yield client.query(TRUST_SCORE_QUERY, {}).toPromise();
    if (error) {
        if (error instanceof CombinedError && error.networkError) {
            throw new NetworkError('pages.administration.dashboard.errors.network');
        }
        throw new Error('pages.administration.dashboard.errors.graph', { cause: error });
    }
    // TODO: add types to graphql query using codegen
    const companyTrustScore = (_c = (_b = (_a = data === null || data === void 0 ? void 0 : data.trustScores) === null || _a === void 0 ? void 0 : _a.company) === null || _b === void 0 ? void 0 : _b.trustScore) === null || _c === void 0 ? void 0 : _c.trustScore;
    const industryTrustScores = (_f = (_e = (_d = data === null || data === void 0 ? void 0 : data.trustScores) === null || _d === void 0 ? void 0 : _d.industry) === null || _e === void 0 ? void 0 : _e.edges) === null || _f === void 0 ? void 0 : _f.map(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (edge) => edge.node.trustScore);
    const recommendations = (_h = (_g = data === null || data === void 0 ? void 0 : data.trustScores) === null || _g === void 0 ? void 0 : _g.company) === null || _h === void 0 ? void 0 : _h.recommendations.sort((a, b) => a.ordinal - b.ordinal);
    const topRecommendation = ((_j = recommendations[0]) === null || _j === void 0 ? void 0 : _j.recommendation) || '';
    return {
        trustScore: companyTrustScore,
        recommendation: topRecommendation,
        percentile: getPercentile(industryTrustScores, companyTrustScore),
    };
});
