import { jsx as _jsx } from "react/jsx-runtime";
import { breadcrumbsHeight, createPlayerContainerStyle } from '../../../commonStyle';
import { usePlatformContext } from '../../../platformContext';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo } from 'react';
export const CodingLabWrapper = (props) => {
    const platformContext = usePlatformContext();
    const [t] = useTranslation();
    const onComplete = props.onComplete;
    useEffect(() => {
        const handleCodingLabsMessage = ({ data }) => {
            if (typeof data !== 'object' || data === null || typeof data.event !== 'string') {
                return;
            }
            if (data.event === 'cl:analytics') {
                const { eventName, eventProps } = data;
                const routeProps = platformContext.rawAnalyticsService.getRouteProps();
                platformContext.rawAnalyticsService.logEvent(eventName, Object.assign(Object.assign({}, eventProps), routeProps));
                return;
            }
            // Only gets triggered when the user clicks "mark as complete" on the coding lab result screen
            if (data.event === 'backToOverview') {
                onComplete();
            }
        };
        window.addEventListener('message', handleCodingLabsMessage);
        return () => {
            window.removeEventListener('message', handleCodingLabsMessage);
        };
    }, [platformContext, onComplete]);
    const iframeStyle = useMemo(() => {
        const baseStyle = createPlayerContainerStyle({ breadcrumbsHeight, showFooter: false });
        return props.hidden ? Object.assign(Object.assign({}, baseStyle), { display: 'none' }) : baseStyle;
    }, [props.hidden]);
    const url = useMemo(() => {
        const config = {
            sessionToken: props.sessionId,
            navigation: {
                onFinished: {
                    backToOverview: t('components.organisms.navigationBar.markAsComplete'),
                },
            },
        };
        return `${props.baseUrl}#${encodeURIComponent(JSON.stringify(config))}`;
    }, [props.baseUrl, props.sessionId, t]);
    return (_jsx("iframe", { src: url, id: "lesson-iframe", style: iframeStyle, "aria-label": t('CODING_LAB'), title: t('CODING_LAB'), allow: "clipboard-read; clipboard-write; display-capture;" }));
};
