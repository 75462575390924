import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography } from '@mui/material';
import { CardTemplate } from '../../../templates/CardTemplate';
import { QuestSelectionModal } from './QuestSelectionModal';
import { QuestHeaderActions } from './QuestHeaderActions';
import { QuestProgressContent } from '../../molecules/QuestProgressContent';
import { QuestPendingContent } from '../../molecules/QuestPendingContent';
import { useState } from 'react';
import { useDashboard } from '../../../context/dashboardContext';
import { useDashboardAnalytics } from '../../../hooks/useDashboardAnalytics';
import { Trans } from 'react-i18next';
const SELECTED_QUEST_KEY = 'dashboard-selected-quest';
export const LearningProgressActive = ({ goals = [], selectedQuest, reportingData }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const { refreshCard } = useDashboard();
    const { emitEvent } = useDashboardAnalytics();
    const handleModalOpen = () => {
        setModalOpen(true);
    };
    const handleModalClose = () => {
        setModalOpen(false);
    };
    const handleSave = (questId) => {
        const newSelectedQuest = goals.find((goal) => goal.goalId === questId);
        if (newSelectedQuest) {
            localStorage.setItem(SELECTED_QUEST_KEY, questId);
            refreshCard('learning-progress');
            // Fire analytics event
            emitEvent('changed_goal');
        }
        setModalOpen(false);
    };
    return (_jsxs(CardTemplate, { children: [_jsxs(CardTemplate.Header, { children: [_jsx(Typography, { variant: "h5", flexWrap: "wrap", children: _jsx(Trans, { i18nKey: "pages.administration.dashboard.learningProgress.title", values: { questName: selectedQuest === null || selectedQuest === void 0 ? void 0 : selectedQuest.name } }) }), _jsx(QuestHeaderActions, { onSelectGoal: handleModalOpen, reportingDataPending: !reportingData, selectedQuestId: selectedQuest === null || selectedQuest === void 0 ? void 0 : selectedQuest.goalId })] }), _jsx(QuestSelectionModal, { open: modalOpen, onClose: handleModalClose, onSave: handleSave, goals: goals, selectedQuestId: selectedQuest === null || selectedQuest === void 0 ? void 0 : selectedQuest.goalId }), _jsx(CardTemplate.Body, { children: !reportingData ? (_jsx(QuestPendingContent, { currentQuest: selectedQuest })) : (_jsx(QuestProgressContent, { reportingData: reportingData })) })] }));
};
