import { z } from 'zod';
import { i18n } from '../../../i18n/i18next';
const roleSchema = z.enum(['company admin', 'developer', 'team manager', 'user', 'cms developer']);
const statusSchema = z.enum(['disabled', 'enabled', 'invited', 'registered']);
const nameSchema = z.object({
    first: z.string(),
    middle: z.string(),
    last: z.string(),
});
const teamSchema = z.object({
    _id: z.coerce.string(),
    name: z.string(),
    tags: z.array(z.string()).default([]),
});
const propertiesSchema = z.object({
    _cid: z.coerce.string(),
    _tid: z.coerce.string(),
    tags: z.array(z.string()).default([]),
    team: teamSchema.optional(),
    registered: z.union([z.null(), z.coerce.date()]),
    lastLogin: z.union([z.null(), z.coerce.date()]),
    lastInvited: z.union([z.null(), z.coerce.date()]),
    lastReminded: z.union([z.null(), z.coerce.date()]),
    lastInviteBounced: z.boolean().nullish(),
    platformManaged: z.boolean().nullish().default(false),
});
const userSchema = z
    .object({
    _id: z.string(),
    status: statusSchema,
    properties: propertiesSchema,
    roles: z.array(roleSchema),
    email: z.string(),
    name: nameSchema,
})
    .transform((data) => {
    var _a;
    return ({
        id: data._id,
        email: data.email,
        status: i18n.format(data.status, 'sentenceCase'),
        name: `${data.name.first} ${data.name.last}`,
        createdAt: data.properties.registered,
        team: (_a = data.properties.team) === null || _a === void 0 ? void 0 : _a.name,
        lastLogin: data.properties.lastLogin,
        platformManaged: data.properties.platformManaged,
    });
});
export const userListSchema = z.array(userSchema);
