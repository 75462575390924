import { jsx as _jsx } from "react/jsx-runtime";
import { challengeplayerBackgroundColor, ChallengePlayerEmbedder, } from '../../../players/ChallengePlayerEmbedder';
import { breadcrumbsHeight, createPlayerContainerStyle } from '../../../commonStyle';
import { useMemo } from 'react';
export const ChallengePlayerWrapper = (props) => {
    const eventHandlers = useMemo(() => {
        return {
            onContinuePressed: props.onContinuePressed,
            onChallengeFinished: props.onChallengeFinished,
        };
    }, [props.onContinuePressed, props.onChallengeFinished]);
    return (_jsx(ChallengePlayerEmbedder, { baseUrl: props.baseUrl, style: style, eventHandlers: eventHandlers, playmode: "quests" }));
};
// "skeleton" should only be a background color that matches the background color of the spinner that CP uses
export const Skeleton = () => _jsx("div", { style: skeletonStyle });
const style = createPlayerContainerStyle({ breadcrumbsHeight, showFooter: false });
const skeletonStyle = Object.assign(Object.assign({}, style), { background: challengeplayerBackgroundColor });
