import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography, useTheme, Box } from '@mui/material';
import { Stack } from '@mui/system';
import { BarChart } from '@securecodewarrior/design-system-react/lib/labs';
import { CardTemplate } from '../../../templates/CardTemplate';
import { useTranslation } from 'react-i18next';
export const LearnerEngagementOnboarding = () => {
    const theme = useTheme();
    const { t } = useTranslation('react_components', { keyPrefix: 'pages.administration.dashboard.learnerEngagement' });
    return (_jsxs(CardTemplate, { children: [_jsx(CardTemplate.Header, { children: _jsx(Typography, { variant: "h5", flexWrap: "wrap", children: t('title') }) }), _jsxs(CardTemplate.Body, { children: [_jsx(Stack, { direction: "column", alignItems: "center", justifyContent: "space-between", gap: 4, children: _jsx(Box, { sx: { width: '100%', height: '100%' }, children: _jsx(BarChart, { series: [
                                    {
                                        data: [10, 10, 10],
                                        color: theme.palette.graph.data.d1,
                                        valueFormatter: () => `0 ${t('labels.activeLearners').toLowerCase()}`,
                                    },
                                ], xAxis: [
                                    {
                                        label: t('graph.xAxis'),
                                        data: ['Jun', 'Jul', 'Aug'],
                                        scaleType: 'band',
                                        tickPlacement: 'middle',
                                    },
                                ], yAxis: [{ label: t('graph.yAxis'), max: 150 }], height: 212, isStacked: false, displayXTitle: true, displayYTitle: true }) }) }), _jsxs(Stack, { gap: 4, children: [_jsx(Typography, { variant: "h6", children: t('engagementData') }), _jsx(Typography, { children: t('addUsers') })] })] })] }));
};
