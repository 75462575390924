import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { Card, RadialChart } from '@securecodewarrior/design-system-react/lib/labs';
import { CardTemplate } from '../../../templates/CardTemplate';
import { useTranslation } from 'react-i18next';
export const TrustScoreEmpty = () => {
    const { t } = useTranslation('react_components', { keyPrefix: 'pages.administration.dashboard.trustScore' });
    return (_jsxs(CardTemplate, { children: [_jsx(CardTemplate.Header, { children: _jsx(Typography, { variant: "h5", flexWrap: "wrap", children: t('title') }) }), _jsxs(CardTemplate.Body, { children: [_jsxs(Stack, { direction: "column", alignItems: "center", gap: 4, children: [_jsx(RadialChart, { data: { value: 0, type: 'fraction', total: 1000, animate: true, name: 'value' }, dataKey: "value", domain: [0, 1000] }), _jsx(Card, { level: 2, sx: { padding: (theme) => theme.spacing(2, 3), height: 38, width: 200 } })] }), _jsxs(Stack, { gap: 4, children: [_jsx(Typography, { variant: "h6", children: t('yourTrustScoreWillShowHere') }), _jsx(Typography, { children: t('description1') }), _jsx(Typography, { children: t('description2') })] })] })] }));
};
