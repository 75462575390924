import angular from 'angular';
import MODULE from './module';

angular.module(MODULE).directive('ngAppLanguage', [
  '$rootScope',
  function ($rootScope) {
    return {
      link: function (scope, element, _attrs) {
        const removeChangeLanguageListener = $rootScope.$on('changeLanguage', (_event, eventData) => {
          element.attr('lang', eventData.lang || 'en');
        });

        scope.$on('$destroy', removeChangeLanguageListener);
      },
    };
  },
]);
