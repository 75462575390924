import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { breadcrumbsHeight, createPlayerContainerStyle } from '../commonStyle';
import { challengeplayerBackgroundColor, ChallengePlayerEmbedder, } from '../players/ChallengePlayerEmbedder';
import { Suspenser } from '@securecodewarrior/design-system-react';
import { usePlatformNavigate } from '../navigation';
import { useParams } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useApiClient } from '../ApiContext';
import { NavigationBar } from '../navigation/NavigationBar';
import { usePlatformContext } from '../platformContext';
import { ActivityStatusPage } from '../../common/components/organisms/Activities/ActivityStatusPage';
import { assertNever } from '@securecodewarrior/design-system-react/lib/utils/functionalHelpers';
export const ChallengePlayerWrapper = () => {
    const { contentId } = useParams();
    if (!contentId) {
        throw new Error('this component may only be used in a router that will set the correct params');
    }
    const platformCtx = usePlatformContext();
    const navigate = usePlatformNavigate();
    const { trpc, wrapErrorPromise } = useApiClient();
    useEffect(() => {
        platformCtx.logAnalyticsEvent({ event: 'Access Challenge', scope: 'explore', challengeId: contentId });
    }, [contentId, platformCtx]);
    const [redoIfCompleted, setRedoIfCompleted] = useState(false);
    const [completionStatus, setCompletionStatus] = useState('in_progress');
    const response = useMemo(() => wrapErrorPromise(trpc.explore.challenge.startOrContinue.mutate({ revisionId: contentId, redoIfCompleted })), [contentId, wrapErrorPromise, redoIfCompleted] // eslint-disable-line react-hooks/exhaustive-deps
    );
    const eventHandlers = useMemo(() => {
        return {
            onChallengeFinished: () => {
                platformCtx.logAnalyticsEvent({ event: 'Finish Challenge', scope: 'explore', challengeId: contentId });
            },
            onContinuePressed: () => {
                setCompletionStatus('passed');
            },
        };
    }, [contentId, platformCtx]);
    const navigateBack = useCallback(() => {
        platformCtx.clearExploreCacheOnNextRequest();
        navigate.to({ pathname: '/explore', search: '', hash: '' });
    }, [navigate, platformCtx]);
    return (_jsx(_Fragment, { children: _jsx(Suspenser, { reader: response, skeleton: _jsxs(_Fragment, { children: [_jsx(NavigationBar, { type: "challenge", module: "explore", status: "none", onBackNavigation: navigateBack }), _jsx("div", { style: Object.assign(Object.assign({}, style), { background: challengeplayerBackgroundColor }) })] }), render: (resp) => resp.kind === 'ok' ? (completionStatus === 'in_progress' ? (_jsxs(_Fragment, { children: [_jsx(NavigationBar, { type: "challenge", module: "explore", status: "in_progress", onBackNavigation: navigateBack }), _jsx(ChallengePlayerEmbedder, { baseUrl: new URL(resp.externalPlayerUrl), style: style, eventHandlers: eventHandlers, playmode: "explore" })] })) : completionStatus === 'passed' ? (_jsxs(_Fragment, { children: [_jsx(NavigationBar, { type: "challenge", module: "explore", status: "passed", date: new Date(), onBackNavigation: navigateBack }), _jsx(ActivityStatusPage, { scope: "explore", variant: "success", contentType: "challenge", contentId: contentId, onClickBackToActivity: undefined })] })) : (assertNever(completionStatus, 'invalid activity status'))) : resp.kind === 'already_completed' ? (_jsxs(_Fragment, { children: [_jsx(NavigationBar, { type: "challenge", module: "explore", status: "passed", date: resp.lastCompleted, onBackNavigation: navigateBack }), _jsx(ActivityStatusPage, { scope: "explore", variant: "already_completed", contentType: "challenge", contentId: contentId, onClickStartRefresher: () => setRedoIfCompleted(true) })] })) : (assertNever(resp, 'invalid state')) }) }));
};
const style = createPlayerContainerStyle({ breadcrumbsHeight, showFooter: false });
