import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Stack, Typography } from '@mui/material';
import { Checkbox } from '@securecodewarrior/design-system-react/lib/wanda';
import { useId } from 'react';
export const OptionCard = ({ title, description, onChange, selected, disabled, titleComponent = 'h3', children, }) => {
    const titleId = useId();
    return (_jsxs(Stack, { direction: "row", sx: (theme) => ({
            borderRadius: theme.shape.border.radius.md,
            border: `1px solid ${selected && !disabled ? theme.palette.container.border.active : theme.palette.container.border.default}`,
            backgroundColor: theme.palette.container.fill.card2,
        }), children: [_jsx(Box, { sx: (theme) => ({ p: 5, borderRight: `1px solid ${theme.palette.container.border.default}` }), children: _jsx(Checkbox, { disabled: disabled, checked: selected, "aria-labelledby": titleId, sx: { padding: 0 }, onChange: (e, checked) => onChange(e, checked) }) }), _jsxs(Stack, { p: 5, gap: 5, flexGrow: 1, children: [_jsxs(Stack, { gap: 3, children: [_jsx(Typography, { id: titleId, component: titleComponent, variant: "h6", children: title }), _jsx(Typography, { variant: "caption", children: description })] }), children] })] }));
};
