import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { breadcrumbsHeight, createPlayerContainerStyle } from '../../commonStyle';
import { challengeplayerBackgroundColor, ChallengePlayerEmbedder, } from '../../players/ChallengePlayerEmbedder';
import { Suspenser } from '@securecodewarrior/design-system-react';
import { usePlatformNavigate } from '../../navigation';
import { useLocation, useParams } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import { useApiClient } from '../../ApiContext';
import { NavigationBar } from '../../navigation/NavigationBar';
import { usePlatformContext } from '../../platformContext';
export const ChallengePlayerWrapper = () => {
    const { contentId } = useParams();
    if (!contentId) {
        throw new Error('this component may only be used in a router that will set the correct params');
    }
    const location = useLocation();
    const platformCtx = usePlatformContext();
    const navigate = usePlatformNavigate();
    const { trpc, wrapErrorPromise } = useApiClient();
    const response = useMemo(() => {
        const promise = wrapErrorPromise(trpc.explore.challenge.startOrContinue.mutate({ revisionId: contentId, isCybermon: true }));
        platformCtx.rawAnalyticsService.logEvent('Access Challenge', {
            scope: 'cybermon',
            challenge_content_slug: contentId,
        });
        return promise;
    }, [contentId, wrapErrorPromise] // eslint-disable-line react-hooks/exhaustive-deps
    );
    const eventHandlers = useMemo(() => {
        return {
            onChallengeFinished: () => {
                platformCtx.rawAnalyticsService.logEvent('Completed Challenge', {
                    scope: 'cybermon',
                });
                platformCtx.logAnalyticsEvent({ event: 'Finish Challenge', scope: 'cybermon', challengeId: contentId });
            },
            onContinuePressed: () => {
                var _a;
                const backLocation = ((_a = location.state) === null || _a === void 0 ? void 0 : _a.from) || { pathname: '/cybermon', search: '', hash: '' };
                navigate.to(backLocation);
            },
        };
    }, [navigate, location, platformCtx, contentId]);
    const navigateBack = useCallback(() => {
        platformCtx.rawAnalyticsService.logEvent('Abandoned Challenge', {
            scope: 'cybermon',
        });
        platformCtx.clearExploreCacheOnNextRequest();
        navigate.to({ pathname: '/cybermon', search: '', hash: '' });
    }, [navigate, platformCtx]);
    function throwInvalidStateError() {
        throw new Error('Invalid state');
    }
    return (_jsxs(_Fragment, { children: [_jsx(NavigationBar, { type: "challenge", module: "cybermon", status: "none", onBackNavigation: navigateBack }), _jsx(Suspenser, { reader: response, skeleton: _jsx("div", { style: Object.assign(Object.assign({}, style), { background: challengeplayerBackgroundColor }) }), render: (resp) => resp.kind === 'already_completed' ? (throwInvalidStateError()) : (_jsx(ChallengePlayerEmbedder, { baseUrl: new URL(resp.externalPlayerUrl), style: style, eventHandlers: eventHandlers, playmode: "cybermon" })) })] }));
};
const style = createPlayerContainerStyle({ breadcrumbsHeight, showFooter: false });
