import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CardTopStatus } from '../../../../common/components/atoms/CardTopStatus';
import { Box, Typography, useTheme } from '@mui/material';
import { Trans } from 'react-i18next';
export const CompletedQuestCard = (props) => {
    const theme = useTheme();
    const statusConfig = {
        optional: {
            fill: theme.palette.badge.accent4.fill,
            border: theme.palette.badge.accent4.border,
            i18nKey: 'pages.myQuests.components.completedQuestCard.optional',
        },
        mandatory: {
            fill: theme.palette.badge.error.fill,
            border: theme.palette.badge.error.border,
            i18nKey: 'pages.myQuests.components.completedQuestCard.mandatory',
        },
        'platform task': {
            fill: theme.palette.badge.default.fill,
            border: theme.palette.badge.default.border,
            i18nKey: 'pages.myQuests.components.completedQuestCard.platformTask',
        },
    }[props.badgeType];
    return (_jsxs(Box, { component: "li", onClick: props.onClick, sx: {
            width: theme.typography.pxToRem(300),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius: theme.shape.border.radius.md,
            border: `1px solid ${theme.palette.container.border.default}`,
            '&:has(button:focus-visible)': {
                outline: `2px solid ${theme.palette.clickable.button.default}`,
            },
            background: theme.palette.container.fill.card1,
            '&:hover': {
                background: theme.palette.container.fill.card2,
            },
            padding: theme.spacing(0, 7, 10, 7),
            cursor: 'pointer',
            gap: 5,
            h3: {
                overflowWrap: 'anywhere',
                textAlign: 'center',
                button: {
                    background: 'unset',
                    font: 'unset',
                    border: 'unset',
                    color: 'unset',
                },
            },
            img: {
                borderRadius: theme.shape.border.radius.md,
                background: theme.palette.container.border.default,
                border: `1px solid ${theme.palette.container.border.default}`,
                width: theme.typography.pxToRem(140),
                height: theme.typography.pxToRem(140),
            },
        }, children: [_jsx(CardTopStatus, { fill: statusConfig.fill, borderColor: statusConfig.border, children: _jsx(Trans, { i18nKey: statusConfig.i18nKey }) }), _jsx(Typography, { variant: "h4", component: "h3", children: _jsx("button", { children: props.title }) }), _jsx("img", { src: props.imageUrl, alt: "" })] }));
};
