const getActivityStatus = (act) => {
    return act.in_progress ? 'started' : act.finished ? 'completed' : act.lastFailed ? 'failed' : 'not started';
};
export const courseActivityAnalytics = (scope, backFromActivityEvent, sessionUser, sendEvent) => {
    var _a, _b;
    const preferredLanguage = ((_b = (_a = sessionUser === null || sessionUser === void 0 ? void 0 : sessionUser.properties) === null || _a === void 0 ? void 0 : _a.profile) === null || _b === void 0 ? void 0 : _b.i18nLanguagePreference) || 'en';
    const enhanceActivityType = (activityType) => {
        if (activityType === 'guideline') {
            return { activityType, preferredLanguage };
        }
        return { activityType };
    };
    return {
        onClickActivity: (activity, categoryId) => {
            sendEvent(Object.assign(Object.assign({ event: 'Click Activity', scope, activityId: activity.id }, enhanceActivityType(activity.kind)), { status: getActivityStatus(activity), categoryId }));
        },
        onActivityAccessed: (activity) => {
            // prettier-ignore
            ({
                walkthrough: () => sendEvent({ event: 'Access Walkthrough', scope, walkthrough_slug: activity.contentId }),
                coding_lab: () => sendEvent({ event: 'Access Coding Lab', scope, coding_lab_slug: activity.contentId }),
                challenge: () => sendEvent({ event: 'Access Challenge', scope, challengeId: activity.contentId }),
                guideline: () => sendEvent({ event: 'Access Guideline', scope, guideline_slug: activity.contentId, preferredLanguage }),
                mission: () => sendEvent({ event: 'Access Mission', scope, mission_slug: activity.contentId }),
                video: () => sendEvent({ event: 'Access Video', scope, video_slug: activity.contentId }),
            })[activity.kind]();
        },
        onActivityFinished: (activity, categoryId) => {
            const { contentId: activityId, kind: activityType } = activity;
            // note: in Explore, these events are named 'Finished Video'/'Finished Guideline' etc etc
            sendEvent(Object.assign(Object.assign({ event: 'Activity Finished', scope, activityId }, enhanceActivityType(activityType)), { categoryId }));
        },
        onNextActivityClicked: (nextActivityInfo) => {
            const { activityType, category: categoryId } = nextActivityInfo;
            sendEvent(Object.assign(Object.assign({ event: 'Next Activity Clicked', scope }, enhanceActivityType(activityType)), { categoryId }));
        },
        onBackFromActivityClicked: (activityType) => {
            sendEvent({ event: backFromActivityEvent, scope, activityType, component: 'NavigationBar' });
        },
    };
};
