import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { DataGrid } from '@securecodewarrior/design-system-react/lib/labs';
import { Stack } from '@mui/material';
import { useTrpcUsers } from '../../hooks/useUsers';
import { useState } from 'react';
import { BulkActionsSelection } from '../molecules/BulkActionsSelection';
export const UsersDataGrid = () => {
    var _a;
    const { users, isLoading, isError, error, gridProps } = useTrpcUsers();
    const [selectedRows, setSelectedRows] = useState([]);
    if (isError) {
        return _jsxs(Stack, { children: ["Error: ", (_a = error === null || error === void 0 ? void 0 : error.message) !== null && _a !== void 0 ? _a : 'Unknown error'] });
    }
    return (_jsx(DataGrid, Object.assign({ variant: "pagination", dataSource: { data: users, loading: isLoading }, name: "users-list", onRowSelectionModelChange: (newSelection) => {
            setSelectedRows(newSelection);
        }, slotProps: {
            toolbar: {
                csvOptions: { disableToolbarButton: true },
                selectionComponents: _jsx(BulkActionsSelection, { selectedRows: selectedRows }),
            },
        } }, gridProps)));
};
