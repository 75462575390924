var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { ZodError } from 'zod';
import { useTrpc } from './useTrpc';
import { createGridProps } from '../utils/createGridProps';
import { userListSchema } from '../schema/userListSchema';
export const useTrpcUsers = () => {
    const { trpc, ctx } = useTrpc();
    const { data, isLoading, isError, error } = useQuery({
        enabled: !!(ctx === null || ctx === void 0 ? void 0 : ctx.sessionId),
        queryKey: ['rpc-get-users-list', ctx === null || ctx === void 0 ? void 0 : ctx.sessionId],
        queryFn: () => __awaiter(void 0, void 0, void 0, function* () {
            const response = yield trpc.userManagement.getUsersList.query();
            if (response.kind !== 'ok')
                throw new Error(response.message);
            return userListSchema.parse(response.users);
        }),
        // Don't retry if it's a parsing error
        retry: (failureCount, error) => (error instanceof ZodError ? false : failureCount < 3),
    });
    const gridProps = useMemo(() => createGridProps(data === null || data === void 0 ? void 0 : data.length), [data === null || data === void 0 ? void 0 : data.length]);
    return {
        users: data !== null && data !== void 0 ? data : [],
        gridProps,
        isLoading,
        isError,
        error,
    };
};
