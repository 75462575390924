var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { CombinedError, gql } from 'urql';
import { z } from 'zod';
import { NetworkError } from './errors';
const SELECTED_QUEST_KEY = 'dashboard-selected-quest';
const QUESTS_MAX = 10000;
const LIST_QUESTS_QUERY = gql `
  query QuestsDetails($questsMax: Int) {
    quests {
      questDetails(first: $questsMax) {
        edges {
          node {
            questSourceId
            name
          }
        }
      }
    }
  }
`;
const QUEST_STATISTICS_QUERY = gql `
  query QuestStatistics($questId: String!) {
    quests {
      report(filter: { questSourceId: $questId }) {
        details {
          questSourceId
          startDate
          endDate
          statistics {
            totalLearners
            completedLearners
            inProgressLearners
            notStartedLearners
            matchedLearners
          }
        }
      }
    }
  }
`;
const statisticsSchema = z.object({
    totalLearners: z.number().nullable().default(0),
    matchedLearners: z.number().nullable().default(0),
    completedLearners: z.number().nullable().default(0),
    inProgressLearners: z.number().nullable().default(0),
    notStartedLearners: z.number().nullable().default(0),
});
const rawQuestsListResponse = z.object({
    quests: z.object({
        questDetails: z.object({
            edges: z.array(z.object({
                node: z.object({
                    questSourceId: z.string(),
                    name: z.string(),
                }),
            })),
        }),
    }),
});
const rawQuestStatisticsResponse = z.object({
    quests: z.object({
        report: z.object({
            details: z.object({
                questSourceId: z.string(),
                startDate: z.string(),
                endDate: z.string(),
                statistics: statisticsSchema,
            }),
        }),
    }),
});
const learningProgressQuestsSchema = z.object({
    name: z.string(),
    goalId: z.string(),
    participantCount: z.number(),
    startDate: z.date(),
    status: z.string(),
});
const learningProgressReportingSchema = z.object({
    goalSourceId: z.string(),
    percentageCompleted: z.number(),
    numberOfLearnersCompletedGoal: z.number(),
    totalAssignedLearners: z.number(),
    learnersWithObjectiveAssigned: z.number(),
    startDate: z.date(),
    endDate: z.date(),
});
const getSelectedQuest = (sortedQuests, latestQuest) => {
    const savedGoalId = localStorage.getItem(SELECTED_QUEST_KEY);
    if (!savedGoalId) {
        return latestQuest;
    }
    const savedQuest = sortedQuests.find((quest) => quest.goalId === savedGoalId);
    if (!savedQuest) {
        localStorage.removeItem(SELECTED_QUEST_KEY);
        return latestQuest;
    }
    return savedQuest;
};
const transformQuestStatistics = (data) => {
    var _a, _b, _c, _d;
    const { details } = data.quests.report;
    const { statistics } = details;
    const totalSum = ((_a = statistics.completedLearners) !== null && _a !== void 0 ? _a : 0) + ((_b = statistics.inProgressLearners) !== null && _b !== void 0 ? _b : 0) + ((_c = statistics.notStartedLearners) !== null && _c !== void 0 ? _c : 0);
    const percentageCompleted = totalSum === 0 ? 0 : (((_d = statistics.completedLearners) !== null && _d !== void 0 ? _d : 0) / totalSum) * 100;
    return learningProgressReportingSchema.parse({
        goalSourceId: details.questSourceId,
        percentageCompleted,
        numberOfLearnersCompletedGoal: statistics.completedLearners,
        totalAssignedLearners: statistics.totalLearners,
        learnersWithObjectiveAssigned: statistics.matchedLearners,
        startDate: new Date(details.startDate),
        endDate: new Date(details.endDate),
    });
};
const fetchQuestStatistics = (urqlClient, goalId) => __awaiter(void 0, void 0, void 0, function* () {
    const { data, error } = yield urqlClient.query(QUEST_STATISTICS_QUERY, { questId: goalId }).toPromise();
    if (error) {
        if (error instanceof CombinedError && error.networkError) {
            throw new NetworkError('pages.administration.dashboard.errors.network');
        }
        throw error;
    }
    const parsedStatistics = rawQuestStatisticsResponse.parse(data);
    return transformQuestStatistics(parsedStatistics);
});
const fetchQuestsList = (urqlClient) => __awaiter(void 0, void 0, void 0, function* () {
    const { data, error } = yield urqlClient.query(LIST_QUESTS_QUERY, { questsMax: QUESTS_MAX }).toPromise();
    if (error) {
        if (error instanceof CombinedError && error.networkError) {
            throw new NetworkError('pages.administration.dashboard.errors.network');
        }
        throw error;
    }
    const parsedData = rawQuestsListResponse.parse(data);
    return parsedData.quests.questDetails.edges;
});
// Main Export
export const fetchLearningProgress = (trpc, urqlClient) => __awaiter(void 0, void 0, void 0, function* () {
    const quests = yield trpc.goalsAdmin.list.query(undefined, { context: { skipBatch: true } });
    if (quests.kind !== 'ok') {
        throw new Error('pages.administration.dashboard.errors.quest');
    }
    try {
        const sortedQuests = quests.data.published.sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());
        const filteredQuests = sortedQuests.filter((quest) => quest.status !== 'archived');
        if (!filteredQuests.length) {
            return { onboarding: true };
        }
        const selectedQuest = getSelectedQuest(sortedQuests, filteredQuests[0]);
        const questsInReporting = yield fetchQuestsList(urqlClient);
        const isQuestPendingInReporting = !questsInReporting.some((edge) => edge.node.questSourceId === (selectedQuest === null || selectedQuest === void 0 ? void 0 : selectedQuest.goalId));
        if (isQuestPendingInReporting) {
            return {
                quests: filteredQuests,
                selectedQuest,
                goalsReportingData: undefined,
                onboarding: false,
            };
        }
        const questsReporting = yield fetchQuestStatistics(urqlClient, selectedQuest.goalId);
        return {
            quests: filteredQuests,
            selectedQuest,
            goalsReportingData: questsReporting,
            onboarding: false,
        };
    }
    catch (error) {
        if (error instanceof NetworkError) {
            throw error;
        }
        throw new Error(`pages.administration.dashboard.errors.learningProgress`, { cause: error });
    }
});
