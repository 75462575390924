import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { usePlatformContext } from '../../platformContext';
import { useApiClient } from '../../ApiContext';
import { TrainingAlert } from './TrainingAlert';
import { Box, Collapse } from '@mui/material';
import { Divider } from '../../Divider';
import { sendPlatformHeaderEvent } from '../../../portal-frontend/components/PlatformHeader';
export const TrainingAlertWrapper = () => {
    const ctx = usePlatformContext();
    const { trpc } = useApiClient();
    const [mandatoryTrainingInfo, setMandatoryTrainingInfo] = useState();
    useEffect(() => {
        var _a;
        if (ctx.sessionUser && !((_a = ctx.sessionUser) === null || _a === void 0 ? void 0 : _a.roles.includes('scw admin'))) {
            ctx.learningModuleService
                .isLearningModuleEnabled('quests')
                .then((enabled) => {
                if (enabled) {
                    trpc.myquests.fetchMandatoryTrainingInfo
                        .query()
                        .then((response) => {
                        setMandatoryTrainingInfo(response);
                        if (response.kind === 'error') {
                            return;
                        }
                        sendPlatformHeaderEvent({
                            event: 'ph:myQuests:setNotification',
                            value: response.kind !== 'all caught up',
                        });
                    })
                        .catch(() => { });
                }
            })
                .catch(() => { });
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    const showBanner = (mandatoryTrainingInfo === null || mandatoryTrainingInfo === void 0 ? void 0 : mandatoryTrainingInfo.kind) === 'taskinfo';
    return (_jsx(Collapse, { in: showBanner, children: showBanner && (_jsxs(_Fragment, { children: [_jsx(Box, { px: 10, py: 3, bgcolor: (theme) => theme.palette.container.fill.nav, children: _jsx(TrainingAlert, { minutesTrainingLeft: mandatoryTrainingInfo.minutesTrainingLeft, daysLeft: mandatoryTrainingInfo.daysLeft, linkToMyQuests: true }) }), _jsx(Divider, { "aria-hidden": true })] })) }));
};
