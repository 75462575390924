import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Divider, Stack, Typography } from '@mui/material';
import { CourseHero } from './components/CourseHero';
import { InfoRounded, MenuBookRounded } from '@mui/icons-material';
import { Card } from '@securecodewarrior/design-system-react/lib/labs';
import { TopicCard, TopicCardContainer } from './components/TopicCard';
import { SplitTopicActivities } from './components/TopicActivities';
import { usePlatformContext } from '../../platformContext';
import { useMemo, useRef } from 'react';
import { usePlatformNavigate } from '../../navigation';
import { useLocation } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { coursesCourseActivityAnalytics, learnAnalytics } from './learn.analytics';
import { usePersistedCourseState } from './usePersistedCourseState';
import { WithFooter } from '../../footer/WithFooter';
import { Skeleton } from '@securecodewarrior/design-system-react';
import { TopicCardHeader } from './components/TopicCardHeader';
const getActivityPath = (targetActivity, course) => {
    let cNr = 0;
    for (const chapter of course.chapters) {
        cNr++;
        let tNr = 0;
        for (const topic of chapter.topics) {
            tNr++;
            const activityNrs = { video: 1, guideline: 1, challenge: 1, coding_lab: 1, walkthrough: 1, mission: 1 };
            for (const activity of topic.activities.concat(topic.validation)) {
                if (activity === targetActivity) {
                    return `chapter/${cNr}/topic/${tNr}/${activity.kind}/${activityNrs[activity.kind]}`;
                }
                activityNrs[activity.kind]++;
            }
        }
    }
};
export const CourseDetailsView = ({ course }) => {
    var _a, _b;
    const { logAnalyticsEvent, sessionUser } = usePlatformContext();
    const analytics = useMemo(() => learnAnalytics(logAnalyticsEvent), [logAnalyticsEvent]);
    const activityAnalytics = coursesCourseActivityAnalytics(logAnalyticsEvent, sessionUser);
    const navigate = usePlatformNavigate();
    const persistedState = usePersistedCourseState(course);
    const { state } = useLocation();
    const highlightedActivity = useRef(state === null || state === void 0 ? void 0 : state.highlightedActivity);
    let highlightedActivityId = null;
    // this could find the wrong activity if the course was changed since starting to play
    if (highlightedActivity.current && course) {
        const { chapterNr, topicNr, activityType, activityNr } = highlightedActivity.current;
        highlightedActivity.current = undefined;
        const topic = (_a = course.chapters[chapterNr - 1]) === null || _a === void 0 ? void 0 : _a.topics[topicNr - 1];
        if (topic) {
            if (!persistedState.isTopicExpanded(course.language.id, topic.category)) {
                persistedState.persistTopicExpandedState(course.language.id, topic.category, true);
            }
            const activities = activityType === 'challenge' ? topic.activities.concat(topic.validation) : topic.activities;
            highlightedActivityId = (_b = activities.filter((a) => a.kind === activityType)[activityNr - 1]) === null || _b === void 0 ? void 0 : _b.id;
        }
    }
    const onStartActivity = (activity, course, topic) => {
        const activitypath = getActivityPath(activity, course);
        navigate.to({ pathname: `learn/course/${course.language.id}/${activitypath}`, search: '', hash: '' });
        activityAnalytics.onClickActivity(activity, topic.category);
    };
    return (_jsx(WithFooter, { wrapperComponent: "main", children: _jsxs(Stack, { sx: { alignSelf: 'center', width: '100%' }, children: [_jsx(CourseHero, { course: course }), _jsxs(Card, { sx: (theme) => ({
                        display: 'flex',
                        gap: 2,
                        alignSelf: 'center',
                        alignItems: 'center',
                        color: theme.palette.text.soft,
                        borderColor: theme.palette.container.border.active,
                        maxWidth: theme.typography.pxToRem(960),
                        px: 6,
                        py: 5,
                        my: 7,
                    }), children: [_jsx(InfoRounded, { fontSize: "large" }), _jsx(Typography, { color: (theme) => theme.palette.text.soft, children: _jsx(Trans, { i18nKey: "pages.learn.course.details.tip" }) })] }), course ? (course.chapters.map((chapter, chapterIndex) => (_jsxs(Stack, { flexGrow: "1", children: [chapterIndex > 0 && _jsx(DividerWithMargin, {}), _jsxs(ChapterContainer, { children: [_jsx(ChapterHeader, { chapter: chapter, chapterNr: chapterIndex + 1 }), chapter.topics.map((topic, topicIndex) => (_jsx(TopicCard, { chapterNr: chapterIndex + 1, initialCollapsed: !persistedState.isTopicExpanded(course.language.id, topic.category), nr: topicIndex + 1, topic: topic, onCollapsedStateChanged: (isNowCollapsed) => {
                                        persistedState.persistTopicExpandedState(course.language.id, topic.category, !isNowCollapsed);
                                        analytics.onTopicCollapsedStateChanged(topic.category, isNowCollapsed);
                                    }, children: _jsx(SplitTopicActivities, { activities: topic.activities, validation: topic.validation, highlightedActivityId: highlightedActivityId, onStartActivity: (a) => onStartActivity(a, course, topic) }) }, topic.id)))] })] }, chapter.name)))) : (_jsxs(_Fragment, { children: [_jsxs(ChapterContainer, { children: [_jsx(ChapterHeader, {}), _jsx(TopicCardContainer, { children: _jsx(TopicCardHeader, {}) })] }), _jsx(DividerWithMargin, {}), _jsxs(ChapterContainer, { children: [_jsx(ChapterHeader, {}), _jsx(TopicCardContainer, { children: _jsx(TopicCardHeader, {}) })] })] }))] }) }));
};
const ChapterContainer = ({ children }) => (_jsx(Stack, { gap: 5, maxWidth: (theme) => theme.typography.pxToRem(960), alignSelf: "center", width: "100%", children: children }));
const DividerWithMargin = () => _jsx(Divider, { sx: { my: 5 } });
const ChapterHeader = ({ chapter, chapterNr }) => {
    var _a;
    const topicStats = chapter
        ? {
            completed: chapter.topics.reduce((acc, topic) => acc + (topic.completedAt ? 1 : 0), 0),
            total: chapter.topics.length,
        }
        : undefined;
    return (_jsxs(Stack, { direction: "row", justifyContent: "space-between", py: 3, alignItems: "center", children: [_jsxs(Stack, { direction: "row", gap: 2, px: 2, alignItems: "center", color: (theme) => theme.palette.text.soft, children: [_jsx(MenuBookRounded, { fontSize: "large" }), _jsx(Typography, { variant: "h5", component: chapterNr ? 'h2' : 'div', color: "inherit", children: chapterNr ? (_jsx(Trans, { i18nKey: "pages.learn.course.details.chapter", values: { nr: chapterNr } })) : (_jsx(Skeleton, { width: "100px", sx: { transform: 'scale(1, .8)' } })) }), _jsx(Typography, { variant: "h5", component: (chapter === null || chapter === void 0 ? void 0 : chapter.name) ? 'h3' : 'div', children: (_a = chapter === null || chapter === void 0 ? void 0 : chapter.name) !== null && _a !== void 0 ? _a : _jsx(Skeleton, { width: "150px", sx: { transform: 'scale(1, .8)' } }) })] }), _jsx(Typography, { variant: "subtitle1", component: "span", color: (theme) => theme.palette.text.title, children: topicStats ? (_jsx(Trans, { i18nKey: "pages.learn.course.details.topicsCompleted", values: topicStats })) : (_jsx(Skeleton, { width: "76px" })) })] }));
};
