var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export const fetchLicenceUsage = (client) => __awaiter(void 0, void 0, void 0, function* () {
    const licenceUsageData = yield client.dashboard.getLicenceUsage.query(undefined, { context: { skipBatch: true } });
    if (licenceUsageData.kind === 'ok') {
        return {
            available: licenceUsageData.available,
            disabledUsers: licenceUsageData.disabledUsers,
            invited: licenceUsageData.invited,
            purchased: licenceUsageData.purchased,
            renewalDate: licenceUsageData.renewalDate,
            uniqueUsers: licenceUsageData.uniqueUsers,
            used: licenceUsageData.used,
        };
    }
    throw new Error('pages.administration.dashboard.errors.licenceUsage');
});
