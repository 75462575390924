import ComponentsTranslationsEnUs from './translations/components/en-US.json';
import ComponentsTranslationsEnGb from './translations/components/en-GB.json';
import ComponentsTranslationsDe from './translations/components/de.json';
import ComponentsTranslationsEs from './translations/components/es.json';
import ComponentsTranslationsFr from './translations/components/fr.json';
import ComponentsTranslationsJa from './translations/components/ja.json';
import ComponentsTranslationsKo from './translations/components/ko.json';
import ComponentsTranslationsZh from './translations/components/zh.json';
import ComponentsTranslationsZhTw from './translations/components/zh-TW.json';
import PlatformTranslationsEnUs from './translations/platform-frontend/generated-main-en.json';
import PlatformTranslationsEnGb from './translations/platform-frontend/generated-main-en-gb.json';
import PlatformTranslationsDe from './translations/platform-frontend/generated-main-de.json';
import PlatformTranslationsEs from './translations/platform-frontend/generated-main-es.json';
import PlatformTranslationsFr from './translations/platform-frontend/generated-main-fr.json';
import PlatformTranslationsJa from './translations/platform-frontend/generated-main-ja.json';
import PlatformTranslationsKo from './translations/platform-frontend/generated-main-ko.json';
import PlatformTranslationsZh from './translations/platform-frontend/generated-main-zh.json';
import PlatformTranslationsZhTw from './translations/platform-frontend/generated-main-zh-tw.json';
import { designSystemResourceBundles as dsBundle } from '@securecodewarrior/design-system-react/lib/i18n/resources';
export const NS_COMPONENTS = 'react_components';
export const NS_PLATFORM_FRONTEND = 'platform_frontend';
export const DS_NS_COMPONENTS = 'react_components_ds';
export const DS_NS_PLATFORM_FRONTEND = 'platform_frontend_ds';
export const designSystemResourceBundles = {
    'de-DE': {
        [NS_COMPONENTS]: ComponentsTranslationsDe,
        [NS_PLATFORM_FRONTEND]: PlatformTranslationsDe,
        [DS_NS_COMPONENTS]: dsBundle['de-DE'].components,
        [DS_NS_PLATFORM_FRONTEND]: dsBundle['de-DE'].platform_frontend,
    },
    'en-US': {
        [NS_COMPONENTS]: ComponentsTranslationsEnUs,
        [NS_PLATFORM_FRONTEND]: PlatformTranslationsEnUs,
        [DS_NS_COMPONENTS]: dsBundle['en-US'].components,
        [DS_NS_PLATFORM_FRONTEND]: dsBundle['en-US'].platform_frontend,
    },
    'en-GB': {
        [NS_COMPONENTS]: ComponentsTranslationsEnGb,
        [NS_PLATFORM_FRONTEND]: PlatformTranslationsEnGb,
        [DS_NS_COMPONENTS]: dsBundle['en-GB'].components,
        [DS_NS_PLATFORM_FRONTEND]: dsBundle['en-GB'].platform_frontend,
    },
    'es-ES': {
        [NS_COMPONENTS]: ComponentsTranslationsEs,
        [NS_PLATFORM_FRONTEND]: PlatformTranslationsEs,
        [DS_NS_COMPONENTS]: dsBundle['es-ES'].components,
        [DS_NS_PLATFORM_FRONTEND]: dsBundle['es-ES'].platform_frontend,
    },
    'fr-FR': {
        [NS_COMPONENTS]: ComponentsTranslationsFr,
        [NS_PLATFORM_FRONTEND]: PlatformTranslationsFr,
        [DS_NS_COMPONENTS]: dsBundle['fr-FR'].components,
        [DS_NS_PLATFORM_FRONTEND]: dsBundle['fr-FR'].platform_frontend,
    },
    'ja-JP': {
        [NS_COMPONENTS]: ComponentsTranslationsJa,
        [NS_PLATFORM_FRONTEND]: PlatformTranslationsJa,
        [DS_NS_COMPONENTS]: dsBundle['ja-JP'].components,
        [DS_NS_PLATFORM_FRONTEND]: dsBundle['ja-JP'].platform_frontend,
    },
    'ko-KR': {
        [NS_COMPONENTS]: ComponentsTranslationsKo,
        [NS_PLATFORM_FRONTEND]: PlatformTranslationsKo,
        [DS_NS_COMPONENTS]: dsBundle['ko-KR'].components,
        [DS_NS_PLATFORM_FRONTEND]: dsBundle['ko-KR'].platform_frontend,
    },
    'zh-CN': {
        [NS_COMPONENTS]: ComponentsTranslationsZh,
        [NS_PLATFORM_FRONTEND]: PlatformTranslationsZh,
        [DS_NS_COMPONENTS]: dsBundle['zh-CN'].components,
        [DS_NS_PLATFORM_FRONTEND]: dsBundle['zh-CN'].platform_frontend,
    },
    'zh-TW': {
        [NS_COMPONENTS]: ComponentsTranslationsZhTw,
        [NS_PLATFORM_FRONTEND]: PlatformTranslationsZhTw,
        [DS_NS_COMPONENTS]: dsBundle['zh-TW'].components,
        [DS_NS_PLATFORM_FRONTEND]: dsBundle['zh-TW'].platform_frontend,
    },
};
export const locales = Object.keys(designSystemResourceBundles);
