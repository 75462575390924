import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from 'react';
import { usePlatformContext } from '../platformContext';
import { useParams } from 'react-router-dom';
import { useApiClient } from '../ApiContext';
import { NavigationBar } from '../navigation/NavigationBar';
import { usePlatformNavigate } from '../navigation';
import { GuidelinePlayer } from '../players/GuidelinePlayer';
import { breadcrumbsHeight, createPlayerContainerStyle } from '../commonStyle';
import { WithFooter } from '../footer/WithFooter';
import { ActivityStatusBanner } from '../../common/components/organisms/Activities/ActivityStatusBanner';
import { assertNever } from '@securecodewarrior/design-system-react/lib/utils/functionalHelpers';
import { ActivityStatusPage } from '../../common/components/organisms/Activities/ActivityStatusPage';
import { Divider } from '@mui/material';
export const GuidelineWrapper = () => {
    var _a, _b, _c;
    const { guidelineId } = useParams();
    if (!guidelineId) {
        throw new Error('this component may only be used in a router that will set the correct params');
    }
    const { handleErrorPromise, trpc } = useApiClient();
    const platformCtx = usePlatformContext();
    const navigate = usePlatformNavigate();
    const preferredLanguage = ((_c = (_b = (_a = platformCtx.sessionUser) === null || _a === void 0 ? void 0 : _a.properties) === null || _b === void 0 ? void 0 : _b.profile) === null || _c === void 0 ? void 0 : _c.i18nLanguagePreference) || 'en';
    const [response, setResponse] = useState(undefined);
    const [completionStatus, setCompletionStatus] = useState('in_progress');
    const [enableMarkAsComplete, setEnableMarkAsComplete] = useState(false);
    const fetch = (redoIfCompleted = false) => {
        setResponse(undefined);
        setCompletionStatus('in_progress');
        setEnableMarkAsComplete(false);
        handleErrorPromise(trpc.explore.guideline.startOrContinue.mutate({ guidelineId, redoIfCompleted }), (r) => {
            setResponse(r);
            platformCtx.logAnalyticsEvent({
                event: 'Access Guideline',
                scope: 'explore',
                preferredLanguage: preferredLanguage,
                guideline_slug: guidelineId,
            });
        });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(fetch, [guidelineId, handleErrorPromise, platformCtx]);
    const navigateBack = useCallback(() => {
        platformCtx.clearExploreCacheOnNextRequest();
        navigate.to({ pathname: '/explore', hash: '', search: '' });
    }, [navigate, platformCtx]);
    const onMarkAsComplete = useCallback(() => {
        setEnableMarkAsComplete(false);
        handleErrorPromise(trpc.explore.guideline.finish.mutate({ guidelineId }), (_r) => {
            setCompletionStatus('passed');
            platformCtx.logAnalyticsEvent({
                event: 'Finish Guideline',
                scope: 'explore',
                preferredLanguage: preferredLanguage,
                guideline_slug: guidelineId,
            });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [guidelineId, handleErrorPromise, navigateBack, platformCtx]);
    const loadingState = () => {
        return _jsx(NavigationBar, { type: "guideline", module: "explore", status: "none", onBackNavigation: navigateBack });
    };
    const alreadyCompletedState = (response) => {
        return (_jsxs(_Fragment, { children: [_jsx(NavigationBar, { type: "guideline", module: "explore", status: "passed", date: response.lastCompleted, onBackNavigation: navigateBack }), _jsxs(GuidelinePlayer, { id: guidelineId, analyticsScope: 'explore', guideline: response.guideline.content, title: response.guideline.title, category: response.guideline.category, onScrolledToEnd: () => setEnableMarkAsComplete(true), onFeedbackProvided: (feedback) => handleErrorPromise(trpc.feedback.guideline.mutate(feedback), () => { }), style: createPlayerContainerStyle({ breadcrumbsHeight, showFooter: true }), children: [_jsx(ActivityStatusBanner, { scope: "explore", variant: "already_completed", contentType: "guideline", contentId: guidelineId, onClickStartRefresher: () => {
                                fetch(true);
                            } }), _jsx(Divider, {})] })] }));
    };
    const justPassedState = () => {
        return (_jsxs(_Fragment, { children: [_jsx(NavigationBar, { type: "guideline", module: "explore", status: "passed", date: new Date(), onBackNavigation: navigateBack }), _jsx(ActivityStatusPage, { scope: "explore", variant: "success", contentType: "guideline", contentId: guidelineId, onClickBackToActivity: () => setCompletionStatus('review') })] }));
    };
    const inProgressState = (response) => {
        return (_jsxs(_Fragment, { children: [_jsx(NavigationBar, { type: "guideline", module: "explore", status: "in_progress", onBackNavigation: navigateBack, onMarkAsComplete: onMarkAsComplete, enableMarkAsComplete: enableMarkAsComplete }), _jsx(GuidelinePlayer, { id: guidelineId, analyticsScope: 'explore', guideline: response.guideline.content, title: response.guideline.title, category: response.guideline.category, onScrolledToEnd: () => setEnableMarkAsComplete(true), onFeedbackProvided: (feedback) => handleErrorPromise(trpc.feedback.guideline.mutate(feedback), () => { }), style: createPlayerContainerStyle({ breadcrumbsHeight, showFooter: true }) })] }));
    };
    const reviewAfterPassedState = (response) => {
        return (_jsxs(_Fragment, { children: [_jsx(NavigationBar, { type: "guideline", module: "explore", status: "passed", date: new Date(), onBackNavigation: navigateBack }), _jsxs(GuidelinePlayer, { id: guidelineId, analyticsScope: 'explore', guideline: response.guideline.content, title: response.guideline.title, category: response.guideline.category, onScrolledToEnd: () => setEnableMarkAsComplete(true), onFeedbackProvided: (feedback) => handleErrorPromise(trpc.feedback.guideline.mutate(feedback), () => { }), style: createPlayerContainerStyle({ breadcrumbsHeight, showFooter: true }), children: [_jsx(ActivityStatusBanner, { scope: "explore", variant: "already_completed", contentType: "guideline", contentId: guidelineId, onClickStartRefresher: () => {
                                fetch(true);
                            } }), _jsx(Divider, {})] })] }));
    };
    const playingState = (response) => {
        return completionStatus === 'passed' ? (_jsx(_Fragment, { children: justPassedState() })) : completionStatus === 'in_progress' ? (_jsx(_Fragment, { children: inProgressState(response) })) : completionStatus === 'review' ? (_jsx(_Fragment, { children: reviewAfterPassedState(response) })) : (assertNever(completionStatus, 'invalid state'));
    };
    return (_jsx(WithFooter, { wrapperComponent: "main", children: !response ? (_jsx(_Fragment, { children: loadingState() })) : response.kind === 'already_completed' ? (_jsx(_Fragment, { children: alreadyCompletedState(response) })) : response.kind === 'ok' ? (_jsx(_Fragment, { children: playingState(response) })) : (assertNever(response, 'invalid state')) }));
};
