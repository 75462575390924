import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { usePlatformContext } from '../../platformContext';
import { usePlatformNavigate } from '../../navigation';
import { useApiClient } from '../../ApiContext';
import { useCallback, useEffect, useMemo } from 'react';
import { Suspenser } from '@securecodewarrior/design-system-react';
import { breadcrumbsHeight, createPlayerContainerStyle } from '../../commonStyle';
import { NavigationBar } from '../../navigation/NavigationBar';
const eventKinds = new Set(['navigateToNextStage', 'navigateToMissions', 'skipChallenge']);
export const MissionPlayerWrapper = ({ type }) => {
    const { contentId, languageFramework } = useParams();
    if (!contentId || !languageFramework)
        throw new Error('this component may only be used in a router that will set the correct params');
    const { t } = useTranslation();
    const platformCtx = usePlatformContext();
    const navigate = usePlatformNavigate();
    const { trpc, wrapErrorPromise } = useApiClient();
    const response = useMemo(() => wrapErrorPromise(trpc.explore.mission.startOrContinue
        .mutate({ missionAlias: contentId, language: languageFramework, isCybermon: true })
        .then((response) => {
        if (response.kind === 'ok') {
            platformCtx.logAnalyticsEvent(response.content_type === 'mission'
                ? { event: 'Access Mission', mission_slug: contentId, scope: 'cybermon' }
                : { event: 'Access Walkthrough', walkthrough_slug: contentId, scope: 'cybermon' });
        }
        return response;
    })), [wrapErrorPromise, contentId, languageFramework, platformCtx] //eslint-disable-line react-hooks/exhaustive-deps
    );
    const navigateBack = useCallback((kind) => {
        if (kind && !eventKinds.has(kind)) {
            const abandonedType = type === 'walkthrough' ? 'Abandoned Walkthrough' : 'Abandoned Mission';
            platformCtx.rawAnalyticsService.logEvent(abandonedType, {
                scope: 'cybermon',
            });
        }
        platformCtx.clearExploreCacheOnNextRequest();
        navigate.to({ pathname: '/cybermon', search: '', hash: '' });
    }, [navigate, platformCtx, type]);
    useEffect(() => {
        const handlePostMessage = (event) => {
            const kind = event.data.event;
            if (kind === 'navigateToNextStage') {
                const eventType = type === 'walkthrough' ? 'Completed Walkthrough' : 'Completed Mission';
                platformCtx.rawAnalyticsService.logEvent(eventType, {
                    scope: 'cybermon',
                });
                if (type === 'walkthrough') {
                    platformCtx.logAnalyticsEvent({ event: 'Finish Walkthrough', scope: 'explore', walkthrough_slug: contentId });
                }
                else {
                    platformCtx.logAnalyticsEvent({ event: 'Finish Mission', scope: 'explore', mission_slug: contentId });
                }
            }
            if (eventKinds.has(kind)) {
                navigateBack(kind);
            }
        };
        window.addEventListener('message', handlePostMessage);
        return () => window.removeEventListener('message', handlePostMessage);
    }, [navigateBack, type, platformCtx, contentId]);
    const usersnapData = {
        [`data-${type}-id`]: contentId,
        [`data-${type}-language`]: languageFramework,
    };
    function throwInvalidStateError() {
        throw new Error('Invalid state');
    }
    return (_jsxs(_Fragment, { children: [_jsx(NavigationBar, { type: type, module: "cybermon", status: "none", onBackNavigation: navigateBack }), _jsx(Suspenser, { reader: response, skeleton: _jsx(_Fragment, {}), render: (x) => x.kind === 'already_completed' ? (throwInvalidStateError()) : (_jsx("iframe", Object.assign({ src: x.playerUrl, id: "adversary", style: iframeStyle, "aria-label": t(x.content_type.toUpperCase()), title: t(x.content_type.toUpperCase()), allow: "clipboard-read; clipboard-write; display-capture;" }, usersnapData))) })] }));
};
const iframeStyle = createPlayerContainerStyle({ breadcrumbsHeight, showFooter: false });
