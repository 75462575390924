import { useRef, useState } from 'react';
import { z } from 'zod';
export const COURSE_STATE_KEY = 'course-state';
const courseStateSchema = z.object({
    savedOn: z.number().optional(),
    expandedTopics: z.record(z.array(z.string())),
});
const loadPersistedState = () => {
    try {
        const state = window.localStorage.getItem(COURSE_STATE_KEY);
        if (state !== null) {
            return courseStateSchema.parse(JSON.parse(state));
        }
    }
    catch (e) {
        console.error(e);
    }
    return { expandedTopics: {} };
};
const persistState = (state) => {
    try {
        window.localStorage.setItem(COURSE_STATE_KEY, JSON.stringify(state));
    }
    catch (e) {
        console.error(e);
    }
};
// eslint-disable-next-line sonarjs/cognitive-complexity
export const usePersistedCourseState = (course) => {
    var _a;
    const [state, setState] = useState(loadPersistedState);
    const lastCourse = useRef();
    if (lastCourse.current !== course) {
        lastCourse.current = course;
        if (course) {
            const lang = course.language.id;
            if (lang in state.expandedTopics) {
                // remove invalid categories
                const validCategories = course.chapters.flatMap((chapter) => chapter.topics.map((topic) => topic.category));
                state.expandedTopics[lang] = state.expandedTopics[lang].filter((x) => validCategories.includes(x));
            }
            const hoursBetween = (new Date().getTime() - ((_a = state.savedOn) !== null && _a !== void 0 ? _a : 0)) / 1000 / 60 / 60;
            if (hoursBetween > 4) {
                const firstUncompleted = course.chapters.flatMap((c) => c.topics).find((t) => t.completedAt === undefined);
                if (firstUncompleted) {
                    state.expandedTopics[course.language.id] = [firstUncompleted.category];
                }
                else {
                    delete state.expandedTopics[course.language.id];
                }
            }
        }
    }
    return {
        isTopicExpanded: (language, category) => { var _a; return (_a = state.expandedTopics[language]) === null || _a === void 0 ? void 0 : _a.includes(category); },
        persistTopicExpandedState: (language, category, expanded) => {
            var _a;
            const prev = (_a = state.expandedTopics[language]) !== null && _a !== void 0 ? _a : [];
            state.expandedTopics[language] = expanded ? prev.concat(category) : prev.filter((x) => x !== category);
            setState(Object.assign(Object.assign({}, state), { savedOn: new Date().getTime() }));
            persistState(state);
        },
    };
};
