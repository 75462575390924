import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApiClient } from '../ApiContext';
import SnackbarAlert from '../questsAdmin/components/molecules/SnackbarAlert';
import { LearningModulePage } from './LearningModulePage';
import { useParams } from 'react-router-dom';
import { usePlatformContext } from '../platformContext';
const defaultModules = {
    quests: false,
    guidedLearning: false,
    legacyReporting: false,
};
export const LearningModuleWrapper = () => {
    var _a, _b;
    const { t } = useTranslation();
    const ctx = usePlatformContext();
    const { handleErrorPromise, trpc } = useApiClient();
    const { companyId: routeCompanyId } = useParams();
    const companyId = routeCompanyId !== null && routeCompanyId !== void 0 ? routeCompanyId : (_b = (_a = ctx.sessionUser) === null || _a === void 0 ? void 0 : _a.properties) === null || _b === void 0 ? void 0 : _b._cid;
    if (!companyId) {
        throw new Error('This component can only be used when linked to a company');
    }
    const [modules, setModules] = useState(defaultModules);
    useEffect(() => {
        // On first render fetch
        handleErrorPromise(trpc.adminLearningModules.fetchLearningModules.query({ companyId }), (r) => {
            setModules(r.modules);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const [snackbarConfig, setSnackbarConfig] = useState({
        message: '',
        severity: 'success',
        open: false,
        key: new Date().getTime(),
    });
    const triggerSnackbar = (message, severity) => {
        setSnackbarConfig({
            message,
            severity,
            open: true,
            key: new Date().getTime(),
        });
    };
    const handleCloseSnackbar = () => {
        setSnackbarConfig((prev) => (Object.assign(Object.assign({}, prev), { open: false })));
    };
    const onChange = (key, enabled) => {
        setModules(Object.assign(Object.assign({}, modules), { [key]: enabled }));
    };
    const onSubmit = () => {
        return handleErrorPromise(trpc.adminLearningModules.saveLearningModules.mutate({
            companyId,
            modules,
        }), 
        // Does this chaining still work?
        () => {
            console.log('reply gotten');
            return ctx.learningModuleService.refreshLearningModules(true).then(() => {
                console.log('refreshed');
                ctx.logAnalyticsEvent({
                    event: 'Learning Modules Updated',
                    learningModules: modules,
                });
                triggerSnackbar(t('pages.administration.learningModules.saved'), 'success');
                ctx.setSessionUserLearningModules(modules);
            });
        });
    };
    return (_jsxs(_Fragment, { children: [_jsx(LearningModulePage, { modules: modules, onChange: onChange, onSubmit: onSubmit }), _jsx(SnackbarAlert, { message: snackbarConfig.message, severity: snackbarConfig.severity, open: snackbarConfig.open, onClose: handleCloseSnackbar }, snackbarConfig.key)] }));
};
