var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useId } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Dialog, FormGroup, Stack, Typography, Unstable_Grid2 as Grid2, dialogClasses, } from '@mui/material';
import { usePlatformContext } from '../../../platformContext';
import { mapCategory } from '../../../utils/mapper';
import { CloseIconButton } from '../../../../common/components/atoms/CloseIconButton';
import { Badge } from '../../../../common/components/atoms/Badge/Badge';
import { ChoiceLabel } from '../../../../common/components/atoms/Choice/ChoiceLabel';
const questTypeColors = {
    mandatory: 'red',
    optional: 'blue',
    'platform task': 'default',
};
export const CompletedQuestDetailModal = (_a) => {
    var { quest } = _a, rest = __rest(_a, ["quest"]);
    const { t } = useTranslation();
    return (_jsxs(Dialog, Object.assign({ sx: (theme) => ({
            [`& .${dialogClasses.paper}`]: {
                borderRadius: theme.shape.border.radius.md,
                border: `1px solid ${theme.palette.container.border.default}`,
                background: theme.palette.container.fill.card1,
                p: 7,
                position: 'relative',
                display: 'flex',
                gap: 5,
                flexDirection: 'column',
            },
        }), "aria-labelledby": "my-quests-completed-quest-detail-modal-title", "aria-describedby": quest.description ? 'my-quests-completed-quest-detail-modal-description' : undefined }, rest, { children: [_jsx(CloseIconButton, { "aria-label": t('common.action.close'), onClick: rest.onClose, sx: {
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                } }), _jsxs(Stack, { gap: 5, direction: "row", children: [_jsx(Box, { component: "img", src: quest.imageUrl, sx: (theme) => ({
                            width: 140,
                            height: 140,
                            border: `2px solid ${theme.palette.container.border.default}`,
                            borderRadius: theme.shape.border.radius.md,
                            flexShrink: 0,
                        }), alt: t('pages.myQuests.completedQuestDetailModal.questBadge') }), _jsxs(Stack, { py: 2, gap: 5, alignItems: "flex-start", children: [_jsx(Badge, { color: questTypeColors[quest.type], children: t(`pages.myQuests.components.completedQuestCard.${quest.type}`) }), _jsx(Typography, { variant: "h4", component: "h2", id: "my-quests-completed-quest-detail-modal-title", children: quest.name }), !!quest.description && (_jsx(Typography, { id: "my-quests-completed-quest-detail-modal-description", children: quest.description }))] })] }), _jsx(Stack, { sx: (theme) => ({
                    gap: 4,
                    pt: 4,
                    flexGrow: 1,
                    alignSelf: 'stretch',
                    borderTop: `1px solid ${theme.palette.container.border.default}`,
                }), role: "list", children: _jsx(CategoryCollection, { id: "custom", name: t('pages.myQuests.completedQuestDetailModal.customVulnerabilities'), categories: quest.categories }) })] })));
};
export const CategoryCollection = (props) => {
    const a11yId = useId();
    const { metadata } = usePlatformContext();
    return (_jsx(Stack, { "aria-labelledby": a11yId, bgcolor: (theme) => theme.palette.container.fill.body, border: (theme) => `1px solid ${theme.palette.container.border.default}`, borderRadius: (theme) => theme.shape.border.radius.md, p: 5, children: _jsxs(Grid2, { container: true, spacing: 5, children: [_jsx(Grid2, { xs: 12, sm: 4, children: _jsx(Typography, { variant: "h6", component: "h3", id: a11yId, children: props.name }) }), _jsx(Grid2, { xs: 12, sm: 8, children: _jsx(FormGroup, { sx: { flexWrap: 'wrap', flexDirection: { sm: 'column', md: 'row' }, gap: 2 }, children: props.categories.map((category) => (_jsx(ChoiceLabel, { children: mapCategory(category, metadata).name }, category))) }) })] }) }));
};
