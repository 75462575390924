import angular from 'angular';
import MODULE from './module';
import { ssoTokenLoginImpl } from './login-helpers';
import { loginSuccessImpl } from './login-helpers';
import signupModalTemplate from '../contextual-microlearning/signup-modal.html';

function updatePageTitleAndDescription($window, title, metaDescription) {
  $window.document.title = title;
  $window.document.getElementsByTagName('meta').title.content = title;
  $window.document.getElementsByTagName('meta').description.content = metaDescription;
}

function defaultRedirects($rootscope, $state, AuthService, Session, USER_ROLES) {
  const senseiAuthToken = AuthService.getSenseiAuthToken();

  if (!Session.user) {
    $state.go('login');
    return;
  }

  if (senseiAuthToken) {
    $state.go('sensei-auth');
    return;
  }

  if (
    !Session.user.properties.profile.isComplete &&
    !Session.isAnonymous() &&
    AuthService.isAuthorized(USER_ROLES.admin)
  ) {
    $state.go('profile'); //redirect to the profile page for the SCW admin
    return;
  }

  if (!Session.user.properties.profile.isComplete && !Session.isAnonymous()) {
    $state.go('home');
    return;
  }

  if (AuthService.isAuthorized(USER_ROLES.admin)) {
    $state.go('admin.companies');
  } else if (
    AuthService.isAuthorized(USER_ROLES.companyAdmin) &&
    Session.user.properties.profile.isComplete &&
    $rootscope.isAdminDashboardEnabled
  ) {
    $state.go('dashboard');
  } else {
    $state.go('home');
  }
}

(function () {
  const app = angular.module(MODULE);

  app.controller('LoginController', [
    '$timeout',
    '$log',
    '$scope',
    '$rootScope',
    '$translate',
    '$uibModal',
    '$uibModalStack',
    'AUTH_EVENTS',
    'USER_ROLES',
    'AuthService',
    '$state',
    '$stateParams',
    'ErrorHandler',
    'Session',
    '$window',
    'AnalyticsService',
    'AnalyticsEvents',
    'SSOSAMLRelayStateService',
    'customerAnalyticsClient',
    function (
      $timeout,
      $log,
      $scope,
      $rootScope,
      $translate,
      $uibModal,
      $uibModalStack,
      AUTH_EVENTS,
      USER_ROLES,
      AuthService,
      $state,
      $stateParams,
      ErrorHandler,
      Session,
      $window,
      AnalyticsService,
      AnalyticsEvents,
      SSOSAMLRelayStateService,
      customerAnalyticsClient
    ) {
      function getProfileFromLocalStorage(): any {
        // see also login-helpers.ts addUserProfileToLocalStorage
        const profile = $window.localStorage.getItem('profile');
        if (!profile) return {};
        try {
          return JSON.parse(profile);
        } catch (error) {
          $window.localStorage.removeItem('profile');
        }
        return {};
      }

      function storeChannelParams() {
        $window.localStorage.removeItem('channel');
        const queryParams = new URLSearchParams($window.location.search);
        queryParams.forEach((value, key) => {
          if (key === 'channel') {
            $window.localStorage.setItem(key, value);
          }
        });
      }

      updatePageTitleAndDescription(
        $window,
        'Login | Secure Code Warrior',
        'Login to access your scalable and framework-specific secure coding training.'
      );

      $scope.language = { i18nLanguagePreference: $translate.use() };
      // Website trial. Retrieve user details for login greeting.
      $scope.registered = false;

      function syncProfileFromLocalStorage() {
        const { firstName, registered } = getProfileFromLocalStorage();
        $scope.registered = !!registered;
        if (registered) {
          $scope.firstName = firstName || '';
        } else {
          $scope.firstName = '';
        }
      }

      if ($state.current.name === 'new-simple-flow.flow' || $state.current.name === 'microlearning.flow') {
        syncProfileFromLocalStorage();
      }
      if ($state.current.name === 'sso-login') {
        $scope.checkingCorporateLogin = true;
      }

      // PORTAL-1236: immediate redirection to SSO login page using scw subdomain.
      let currentDomain = $window.location.hostname.toLowerCase();
      const defaultDomain = $window.SCW_ENV.DefaultUiHost.toLowerCase().match(
        /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n]+)/im
      )[1];

      if ($stateParams && $stateParams.d) {
        currentDomain = $stateParams.d;
      }
      // PORTAL-1783: override domain redirection through ?d= parameter

      $log.debug('Default and current domains ', currentDomain, defaultDomain);

      // For non-token logins, redirect to SSO login based on the domain in the incoming state parameter 'd'
      if ($scope.requestedStateParams && $scope.requestedStateParams.d && $state.current.name !== 'sso-login') {
        const whitelistedStates = ['assessments.view.attempt', 'metrics', 'assessments.list', 'admin'];
        if (whitelistedStates.indexOf($scope.requestedState) > -1) {
          $window.localStorage.setItem('ssodomain', $scope.requestedStateParams.d);
        }
      }

      const queryParams = new URLSearchParams($window.location.search);
      const activationToken = queryParams.get('activationToken');

      $scope.sideEffectsFinished = (async () => {
        // check our current domain for a custom xyz.securecodewarrior.com domain
        // redirect to SSO login page if matching a configured SSO company
        // or redirect to default domain if not matching
        const redirected = await redirectToSSOIfApplicable(currentDomain);
        if (!redirected) {
          // Redirect to invite-accept if an active activationToken is supplied
          await redirectIfAwaitingActivation();
        }
      })();

      // check a domain to see if it matches a configured SSO company and redirect to SSO login page
      async function redirectToSSOIfApplicable(domain) {
        let redirected = false;
        if (domain.indexOf(defaultDomain) !== 0 && $state.current.name !== 'sso-login') {
          $rootScope.loading++;
          $rootScope.appLoaded = false;
          await AuthService.checkAuthContext('sso-check@' + domain)
            .then(function (data) {
              $log.debug('Authentication context check: ', data);
              if (data && data.scw_configured) {
                if (!data.login_url) {
                  $translate(['SSO_IMPROPERLY_CONFIGURED_FOR_YOUR_COMPANY_CONTACT_YOUR_ADMIN']).then(
                    function (translations) {
                      return ErrorHandler.addHttpError(
                        translations.SSO_IMPROPERLY_CONFIGURED_FOR_YOUR_COMPANY_CONTACT_YOUR_ADMIN
                      );
                    }
                  );
                }
                $log.debug('Redirecting to SSO: ', data);

                $window.location.href = data.login_url;
                redirected = true;
              } else {
                $log.debug('Redirecting to default portal: ', data);
                $window.location.href = $window.SCW_ENV.DefaultUiHost;
              }
            })
            .catch(function (_response) {
              $scope.checkingCorporateLogin = false;
              $rootScope.appLoaded = true;
            })
            .finally(() => {
              $timeout(() => {
                $rootScope.appLoaded = true;
              }, 2000);
              $rootScope.loading--;
              if ($rootScope.loading < 0) $rootScope.loading = 0;
            });
        }
        return redirected;
      }

      async function redirectIfAwaitingActivation() {
        try {
          if (activationToken) {
            const awaitingActivation = await checkActivationToken(activationToken);
            if (awaitingActivation) {
              $state.go('invite-accept', { token: activationToken });
            }
          }
        } catch (error) {
          $log.debug(error);
          throw error;
        }
      }

      async function checkActivationToken(token) {
        try {
          const data = await AuthService.getEmailByActivationToken(token);
          return data && data.email && data.tokenIsActive;
        } catch (error) {
          $log.debug(error);
        }
        return false;
      }

      // explicit call to redirectToSSOIfApplicable if user has previously used SSO to return to SSO login page
      $scope.goToSSOLogin = function () {
        storeChannelParams();
        const ssodomain = $window.localStorage.getItem('ssodomain');
        $scope.checkingCorporateLogin = true;
        $rootScope.appLoaded = false;
        $window.localStorage.removeItem('ssodomain');
        redirectToSSOIfApplicable(ssodomain);
      };

      const cachedLoginImageUrl = $window.sessionStorage.getItem('loginImageUrl');
      if (cachedLoginImageUrl) {
        $scope.loginImageUrl = cachedLoginImageUrl;
      } else if ($stateParams.eid && $stateParams.eid !== 'true') {
        // load company-specific login image
        // try all endpoints but if all fail then it will fallback to the standard image
        AuthService.loginImage($stateParams.eid)
          .then(function () {
            $scope.loginImageUrl = $window.SCW_ENV.ApiEndpoint + '/public/login-image/' + $stateParams.eid;
            $window.sessionStorage.setItem('loginImageUrl', $scope.loginImageUrl);
          })
          .catch(function () {});
      }

      $scope.credentials = {
        email: '',
        pass: '',
        remember: false,
      };

      $scope.$on('$destroy', function () {
        if ($window.emailRegex.test($scope.credentials.email)) {
          $rootScope.receivedEmail = $scope.credentials.email;
        }
      });

      if ($rootScope.loginFlashMesage) {
        Session.clearSession();
        $scope.flashMessage = $rootScope.loginFlashMesage;
        $rootScope.loginFlashMesage = null;
      }
      // Authenticate user through SSO one-time-login token.
      $scope.ssoTokenLogin = async function (domain, relayState, companyContext) {
        await ssoTokenLoginImpl(
          domain,
          relayState,
          $rootScope,
          $scope,
          AuthService,
          $timeout,
          $translate,
          $log,
          ErrorHandler,
          AUTH_EVENTS,
          $window,
          companyContext,
          $state
        );
      };
      // fire SSO login if sso-login state.
      if ($state.current.name == 'sso-login') {
        const { domain, RelayState, cciv, ccdata } = $stateParams;
        const companyContext =
          cciv && ccdata ? { cciv: decodeURIComponent(cciv), ccdata: decodeURIComponent(ccdata) } : undefined;
        $scope.ssoTokenLogin(domain, RelayState, companyContext);
      }

      $scope.authui = $scope.authui || {};
      $scope.authui.emailError = false;
      $scope.authui.multipleCompaniesOrSecurityGroupsSameDomain = false;
      $scope.authui.passwordError = false;

      // Reusing the existing logic to focus on the appropriate input fields
      $scope.setFocusToInputOfType = function (type) {
        setTimeout(function () {
          angular.element(`.login-form form input[type=${type}]`).trigger('focus');
        }, 0); // off the loop
      };

      $scope.checkAuthScope = function () {
        // if shorthand email is given, expand it before running other logic
        $scope.credentials.email = $scope.expandShorthandEmail($scope.credentials.email.toLowerCase());

        const email = $scope.credentials.email;
        $scope.authui.emailError = false;
        $scope.authui.multipleCompaniesOrSecurityGroupsSameDomain = false;

        if ($window.emailRegex.test(email)) {
          $rootScope.disableButton('login');
          AuthService.addLoading();
          AuthService.checkAuthContext(email)
            .then(function (data) {
              if (data && data.scw_configured) {
                // go to SAML redirection stuff
                if (!data.login_url) {
                  $translate(['SSO_IMPROPERLY_CONFIGURED_FOR_YOUR_COMPANY_CONTACT_YOUR_ADMIN']).then(
                    function (translations) {
                      return ErrorHandler.addHttpError(
                        translations.SSO_IMPROPERLY_CONFIGURED_FOR_YOUR_COMPANY_CONTACT_YOUR_ADMIN
                      );
                    }
                  );
                }
                storeChannelParams();
                window.location.href = data.login_url;
              } else if (data.multiple_companies_or_security_groups_same_domain) {
                //  FDT-220 check if more than one company configured to use same domain.
                //  If yes, error as we have no way of knowing which IDP details to use.
                $scope.$apply(function () {
                  $scope.authui.multipleCompaniesOrSecurityGroupsSameDomain = true;
                  AuthService.removeLoading();
                });
              } else {
                $log.debug('Promoting local-db authentication.', data);
                $scope.$apply(function () {
                  $scope.authui.step = 'password';
                });
                $scope.setFocusToInputOfType('password');
              }
            })
            .catch(function (_response) {
              $translate(['UNABLE_TO_LOGIN_USING_EMAIL'], { email: $scope.credentials.email }).then(
                function (translations) {
                  ErrorHandler.addError(translations.UNABLE_TO_LOGIN_USING_EMAIL);
                }
              );
            })
            .finally(function () {
              AuthService.removeLoading();
              $rootScope.enableButton('login');
            });
        } else {
          // invalid email provided
          $scope.authui.emailError = true;
          $scope.setFocusToInputOfType('text');
        }
      };

      $scope.loginSuccess = async function (data, relayState) {
        updatePageTitleAndDescription(
          $window,
          'Secure Code Warrior',
          'Secure your code from the start with gamified, scalable online security training for software developers'
        );

        await loginSuccessImpl(
          data,
          relayState,
          AuthService,
          $rootScope,
          AnalyticsService,
          customerAnalyticsClient,
          AnalyticsEvents,
          $state,
          $log,
          Session,
          $window,
          $translate,
          swal,
          defaultRedirects,
          $uibModalStack,
          ErrorHandler,
          AUTH_EVENTS,
          USER_ROLES,
          SSOSAMLRelayStateService
        );
      };

      $scope.showSignupModal = function () {
        $scope.cancel();
        $uibModal
          .open({
            templateUrl: signupModalTemplate,
            windowClass: 'simple-flow-modal-z-index-hack',
            controller: 'SignupModalController',
            size: 'lg',
            resolve: {
              translations: {
                modalTitle: 'SIGN_UP',
                modalIntroText: 'SIMPLE_FLOW_SIGNUP_MODAL_INTRO_TEXT_DURING_CHALLENGE',
              },
              sourcePage: function () {
                return 'worldmap leaderboard';
              },
            },
          })
          .result.catch(angular.noop);
      };

      $scope.showSignupModalContextual = function () {
        $scope.cancel();
        $uibModal
          .open({
            animation: true,
            backdrop: 'static',
            templateUrl: signupModalTemplate,
            windowClass: 'simple-flow-modal-z-index-hack',
            controller: 'ContextualMicroLearningSignupModalController',
            size: 'lg',
            resolve: {
              translations: {
                modalTitle: 'SIGN_UP',
                modalIntroText: 'SIMPLE_FLOW_SIGNUP_MODAL_INTRO_TEXT_DURING_CHALLENGE',
              },
              sourcePage: function () {
                return 'worldmap leaderboard';
              },
            },
          })
          .result.catch(angular.noop);
      };

      //Website-trial use. Close login-modal
      $scope.cancel = function () {
        $uibModalStack.dismissAll();
      };

      function clearLocalStorageProfile() {
        $window.localStorage.removeItem('profile');
        $window.localStorage.removeItem('ssodomain');
        AuthService.clearSSOCompanyContext();
        Session.destroy();
      }

      //Website-trial use. For Clearing localstorage data
      //when different user access the page
      $scope.clear = function () {
        // Wrong person
        clearLocalStorageProfile();
        syncProfileFromLocalStorage();
      };

      $scope.login = function (credentials) {
        $translate([
          'WRONG_USERNAME_PASSWORD_OR_INACTIVE_ACCOUNT',
          'LOGIN_FAILED',
          'WRONG_USERNAME_PASSWORD_OR_INACTIVE_ACCOUNT',
          'ACCOUNT_HAS_BEEN_LOCKED_TEMPORARILY_PLEASE_TRY_AGAIN_LATER',
          'USER_ACCOUNT_DISABLED',
        ]).then(function (translations) {
          $rootScope.disableButton('login');

          if (!credentials.pass || credentials.pass.length < 1) {
            $scope.checkAuthScope();
            $scope.authui.passwordError = true;
            return;
          }

          $scope.authui.passwordError = false;
          AuthService.addLoading();
          AuthService.login(credentials)
            .then(function (data) {
              if (data != undefined) {
                storeChannelParams();
                $scope.loginSuccess(data, null);
                $uibModalStack.dismissAll();
              }
            })
            .catch(function (response) {
              $rootScope.$broadcast(AUTH_EVENTS.loginFailed);
              if (
                response.status !== 0 &&
                ((response.data && response.data.error && response.data.error == 'Invalid username/password') ||
                  (response.data && response.data == 'Invalid username/password') ||
                  (response.data && response.data.error && response.data.error == 'Invalid request parameters'))
              ) {
                ErrorHandler.addError(translations.WRONG_USERNAME_PASSWORD_OR_INACTIVE_ACCOUNT);
              } else {
                if (response.data && response.data.error === 'Wrong username/password or currently inactive account.') {
                  response.data.error = translations.WRONG_USERNAME_PASSWORD_OR_INACTIVE_ACCOUNT;
                  ErrorHandler.addHttpError(translations.LOGIN_FAILED, response);
                } else if (
                  response.data &&
                  response.data.error === 'Account has been locked temporarily. Please try again later.'
                ) {
                  response.data.error = translations.ACCOUNT_HAS_BEEN_LOCKED_TEMPORARILY_PLEASE_TRY_AGAIN_LATER;
                  ErrorHandler.addHttpError(translations.LOGIN_FAILED, response);
                } else if (response.message === 'User account is not active') {
                  ErrorHandler.addError(translations.USER_ACCOUNT_DISABLED);
                } else {
                  ErrorHandler.addHttpError(translations.LOGIN_FAILED, response);
                }
              }
            })
            .finally(function () {
              AuthService.removeLoading();
              $rootScope.enableButton('login');
            });
        });
      };
    },
  ]);
})();
