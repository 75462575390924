import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Typography, Stack } from '@mui/material';
import { Card, RadialChart } from '@securecodewarrior/design-system-react/lib/labs';
import { useTranslation, Trans } from 'react-i18next';
export const QuestPendingContent = ({ currentQuest }) => {
    const { t } = useTranslation('react_components', { keyPrefix: 'pages.administration.dashboard.learningProgress' });
    return (_jsxs(_Fragment, { children: [_jsxs(Stack, { direction: "column", alignItems: "center", justifyContent: "space-between", gap: 4, children: [_jsx(RadialChart, { data: {
                            value: 0,
                            type: 'percentage',
                            animate: true,
                            name: 'value',
                        }, dataKey: "value", domain: [0, 100] }), _jsx(Card, { level: 2, sx: { padding: (theme) => theme.spacing(2, 3), height: 38, width: 200 } })] }), _jsxs(Stack, { gap: 2, children: [_jsx(Typography, { variant: "h6", children: t('questDataWillShowHere') }), _jsx(Typography, { children: _jsx(Trans, { i18nKey: "pages.administration.dashboard.learningProgress.successfullyCreated", values: { questName: currentQuest === null || currentQuest === void 0 ? void 0 : currentQuest.name } }) }), _jsx(Typography, { children: t('pleaseAllow') })] })] }));
};
