import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Tooltip } from '@securecodewarrior/design-system-react/lib/wanda';
import { KeyboardArrowDown } from '@mui/icons-material';
import { Divider } from '@mui/material';
import { useState } from 'react';
import { BulkActionsMenu, BulkActionsMenuItem } from '../../styled/BulkActionsMenu.styled';
import { useTranslation } from 'react-i18next';
import { DeleteIcon } from '../../../common/icons';
export const BulkActionsSelection = ({ selectedRows }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const menuOpen = Boolean(anchorEl);
    const { t } = useTranslation('react_components', { keyPrefix: 'pages.administration.userManagement' });
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleMenuItemClick = (action) => {
        if (selectedRows.length === 0) {
            alert('No rows selected');
        }
        else {
            alert(`${action} for users: ${selectedRows.join(', ')}`);
        }
        handleClose();
    };
    return (_jsxs(_Fragment, { children: [_jsx(Tooltip, { title: t('bulkActionsTooltip'), arrow: true, disableHoverListener: selectedRows.length > 0, children: _jsx("span", { children: _jsx(Button, { variant: "outlined", endIcon: _jsx(KeyboardArrowDown, {}), onClick: handleClick, disabled: selectedRows.length === 0, "data-testid": "bulk-actions-button", "aria-controls": menuOpen ? 'bulk-actions-menu' : undefined, "aria-haspopup": "true", "aria-expanded": menuOpen ? 'true' : undefined, children: t('bulkActions') }) }) }), _jsxs(BulkActionsMenu, { id: "bulk-actions-menu", anchorEl: anchorEl, open: menuOpen, onClose: handleClose, anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                }, transformOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                }, MenuListProps: {
                    'aria-labelledby': 'bulk-actions-button',
                    dense: true,
                }, children: [_jsx(BulkActionsMenuItem, { onClick: () => handleMenuItemClick('Change teams'), children: t('bulkActionsMenu.changeTeams') }), _jsx(BulkActionsMenuItem, { onClick: () => handleMenuItemClick('Manage tags'), children: t('bulkActionsMenu.manageTags') }), _jsx(Divider, {}), _jsx(BulkActionsMenuItem, { onClick: () => handleMenuItemClick('Reinvite users'), children: t('bulkActionsMenu.reinviteUsers') }), _jsx(BulkActionsMenuItem, { onClick: () => handleMenuItemClick('Enable users'), children: t('bulkActionsMenu.enableUsers') }), _jsx(BulkActionsMenuItem, { onClick: () => handleMenuItemClick('Disable users'), children: t('bulkActionsMenu.disableUsers') }), _jsx(Divider, {}), _jsx(BulkActionsMenuItem, { onClick: () => handleMenuItemClick('Reset passwords'), children: t('bulkActionsMenu.resetPasswords') }), _jsx(BulkActionsMenuItem, { onClick: () => handleMenuItemClick('Change roles'), children: t('bulkActionsMenu.changeRoles') }), _jsx(Divider, {}), _jsxs(BulkActionsMenuItem, { hasIcon: true, onClick: () => handleMenuItemClick('Delete users'), sx: { color: 'error.main' }, children: [_jsx(DeleteIcon, { sx: { fontSize: 16 } }), t('bulkActionsMenu.deleteUsers')] })] })] }));
};
