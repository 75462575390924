import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { assertNever } from '@securecodewarrior/design-system-react/lib/utils/functionalHelpers';
import { ChallengePlayerWrapper, Skeleton as ChallengePlayerWrapperSkeleton } from './ChallengePlayerWrapper';
import { useApiClient } from '../../../ApiContext';
import { VideoPlayerBase } from '../../../players/VideoPlayerBase';
import { MissionPlayerWrapper } from './MissionPlayerWrapper';
import { usePlatformNavigate } from '../../../navigation';
import { useLocation } from 'react-router-dom';
import { useEffect, useMemo, useState, useRef } from 'react';
import { CodingLabWrapper } from './CodingLabWrapper';
import { NavigationBar } from '../../../navigation/NavigationBar';
import { GuidelinePlayer } from '../../../players/GuidelinePlayer';
import { usePlatformContext } from '../../../platformContext';
import { mapCategory } from '../../../utils/mapper';
import { breadcrumbsHeight, createPlayerContainerStyle } from '../../../commonStyle';
import { Divider } from '../../../Divider';
import { WithFooter } from '../../../footer/WithFooter';
import { NewCodingLabAvailable } from '../../../../common/components/organisms/NewCodingLabAvailable/NewCodingLabAvailable';
import { ActivityStatusBanner } from '../../../../common/components/organisms/Activities/ActivityStatusBanner';
import { ActivityStatusPage } from '../../../../common/components/organisms/Activities/ActivityStatusPage';
// eslint-disable-next-line sonarjs/cognitive-complexity
export const CourseActivity = (props) => {
    const location = useLocation();
    const navigate = usePlatformNavigate();
    const { trpc, handleErrorPromise } = useApiClient();
    const metadata = usePlatformContext().metadata;
    const [isMarkAsCompleteInFlight, setIsMarkAsCompleteInFlight] = useState(false);
    const [onMarkAsComplete, setOnMarkAsComplete] = useState();
    const [enableMarkAsComplete, setEnableMarkAsComplete] = useState(false);
    const [completionStatus, setCompletionStatus] = useState('in_progress');
    const [shouldShowUpdatedLab, setShouldShowUpdatedLab] = useState(false);
    const [discardingLab, setDiscardingLab] = useState(false);
    const [cooldownExpiresAt, setCooldownExpiresAt] = useState(new Date());
    const analytics = props.analytics;
    const [response, setResponse] = useState();
    const lastAccessedContentRef = useRef();
    if (response && response.activity.contentId !== lastAccessedContentRef.current) {
        lastAccessedContentRef.current = response.activity.contentId;
        analytics.onActivityAccessed(response.activity);
    }
    const fetch = (redoIfCompleted = false) => {
        setResponse(undefined);
        setEnableMarkAsComplete(false);
        setOnMarkAsComplete(undefined);
        setCompletionStatus('in_progress');
        handleErrorPromise(props.startOrContinueFn(redoIfCompleted), (response) => {
            if (response.kind === 'ok') {
                if (response.activity.kind === 'guideline') {
                    const { category, attemptId } = response.activity;
                    setOnMarkAsComplete(() => {
                        return () => {
                            setEnableMarkAsComplete(false);
                            setIsMarkAsCompleteInFlight(true);
                            handleErrorPromise(trpc.quests.finishGuideline.mutate({ questId: props.courseId, attemptId }), () => {
                                analytics.onActivityFinished(response.activity, category);
                                setCompletionStatus('passed');
                                setIsMarkAsCompleteInFlight(false);
                            });
                        };
                    });
                }
                else if (response.activity.kind === 'video') {
                    setOnMarkAsComplete(() => {
                        return () => {
                            throw new Error('this button should not be enabled when the video is not fully watched');
                        };
                    });
                }
                else if (response.activity.kind === 'coding_lab') {
                    setShouldShowUpdatedLab(!!response.activity.newVersionAvailable);
                }
            }
            else if (response.kind === 'already_failed') {
                setCooldownExpiresAt(response.cooldownExpiresAt);
            }
            setResponse(response);
        });
    };
    useEffect(fetch, [props.courseId, props.startOrContinueFn]); // eslint-disable-line react-hooks/exhaustive-deps
    const getNextActivityFrontendPath = props.getNextActivityFrontendPath;
    const goToNextActivity = useMemo(() => {
        const nextActivity = response === null || response === void 0 ? void 0 : response.nextActivity;
        if (nextActivity) {
            return () => {
                navigate.to({ pathname: getNextActivityFrontendPath(nextActivity), search: '', hash: '' });
                analytics.onNextActivityClicked(nextActivity);
            };
        }
    }, [response, analytics, getNextActivityFrontendPath, navigate]);
    const goBack = () => {
        var _a;
        analytics.onBackFromActivityClicked(props.activityType);
        return navigate.to(((_a = location.state) === null || _a === void 0 ? void 0 : _a.from) || { pathname: props.returnPath, search: '', hash: '' }, {
            state: props.backNavigationData,
        });
    };
    const discardCodingLab = (attemptId) => {
        setDiscardingLab(true);
        handleErrorPromise(trpc.codingLabs.discard.mutate({ attemptId }), () => {
            fetch();
            setDiscardingLab(false);
        });
    };
    const onClickBackToActivity = props.activityType === 'guideline' || props.activityType === 'video' ? fetch : undefined;
    const activityStatus = (response === null || response === void 0 ? void 0 : response.kind) === 'ok'
        ? completionStatus
        : (response === null || response === void 0 ? void 0 : response.kind) === 'already_completed'
            ? 'passed'
            : (response === null || response === void 0 ? void 0 : response.kind) === 'already_failed'
                ? 'failed'
                : 'in_progress';
    // eslint-disable-next-line sonarjs/cognitive-complexity
    const showPlayer = (response) => {
        const a = response.activity;
        return a.kind === 'video' ? (_jsx(VideoPlayerBase, { video: a.video, analyticsScope: props.analyticsScope, onComplete: (timeSpent) => {
                const videoId = a.video.contentId;
                const category = a.video.category;
                setEnableMarkAsComplete(true);
                setOnMarkAsComplete(() => {
                    return () => {
                        setEnableMarkAsComplete(false);
                        setIsMarkAsCompleteInFlight(true);
                        const questId = props.courseId;
                        const secondsSpent = Math.round(timeSpent);
                        handleErrorPromise(trpc.quests.finishVideo.mutate({ questId, videoId, secondsSpent }), () => {
                            analytics.onActivityFinished(response.activity, category);
                            setCompletionStatus('passed');
                            setIsMarkAsCompleteInFlight(false);
                        });
                    };
                });
            } })) : a.kind === 'guideline' ? (_jsx(GuidelinePlayer, { id: a.contentId, analyticsScope: props.analyticsScope, style: createPlayerContainerStyle({ breadcrumbsHeight, showFooter: true }), guideline: a.guideline, title: mapCategory(a.category, metadata).name, category: a.category, onScrolledToEnd: () => setEnableMarkAsComplete(true), onFeedbackProvided: (feedback) => handleErrorPromise(trpc.feedback.guideline.mutate(feedback), () => { }) })) : a.kind === 'challenge' ? (_jsx(ChallengePlayerWrapper, { baseUrl: new URL(a.playerUrl), onChallengeFinished: (passed) => {
                if (!passed) {
                    setCooldownExpiresAt(new Date(new Date().getTime() + 5 * 60 * 1000));
                }
                analytics.onActivityFinished(a, a.category);
            }, onContinuePressed: (passed) => setCompletionStatus(passed ? 'passed' : 'failed') })) : a.kind === 'coding_lab' ? (_jsxs(_Fragment, { children: [shouldShowUpdatedLab && (_jsx(NewCodingLabAvailable, { onClickLoadOldSession: () => setShouldShowUpdatedLab(false), onClickRestartLab: () => discardCodingLab(a.attemptId), scope: props.analyticsScope, labId: a.contentId.split(':')[0], disabled: discardingLab })), _jsx(CodingLabWrapper, { baseUrl: a.playerUrl, sessionId: a.sessionId, hidden: shouldShowUpdatedLab, onComplete: () => {
                        analytics.onActivityFinished(a, a.category);
                        setCompletionStatus('passed');
                    } })] })) : a.kind === 'mission' || a.kind === 'walkthrough' ? (_jsx(MissionPlayerWrapper, { missionId: a.contentId, language: a.language, playerUrl: a.playerUrl, onComplete: () => {
                analytics.onActivityFinished(a, a.category);
                setCompletionStatus('passed');
            } })) : (assertNever(a, 'invalid state'));
    };
    const showAlreadyCompleted = (response) => {
        return response.activity.kind === 'guideline' ? (_jsxs(GuidelinePlayer, { id: response.activity.contentId, analyticsScope: props.analyticsScope, style: createPlayerContainerStyle({ breadcrumbsHeight, showFooter: true }), guideline: response.activity.guideline, title: mapCategory(response.activity.category, metadata).name, category: response.activity.category, onFeedbackProvided: (feedback) => handleErrorPromise(trpc.feedback.guideline.mutate(feedback), () => { }), children: [_jsx(ActivityStatusBanner, { scope: props.analyticsScope, variant: "already_completed", contentType: props.activityType, contentId: response.activity.contentId, onClickStartRefresher: () => fetch(true) }), _jsx(Divider, {})] })) : response.activity.kind === 'video' ? (_jsxs(_Fragment, { children: [_jsx(ActivityStatusBanner, { scope: props.analyticsScope, variant: "already_completed", contentType: props.activityType, contentId: response.activity.contentId, onClickStartRefresher: () => fetch(true) }), _jsx(Divider, {}), _jsx(VideoPlayerBase, { video: response.activity.video, analyticsScope: props.analyticsScope })] })) : (_jsx(ActivityStatusPage, { scope: props.analyticsScope, variant: "already_completed", contentType: props.activityType, contentId: response.activity.contentId, onClickStartRefresher: () => fetch(true) }));
    };
    return (_jsxs(WithFooter, { children: [activityStatus === 'in_progress' ? (_jsx(NavigationBar, { type: props.activityType, module: props.analyticsScope, onBackNavigation: goBack, enableMarkAsComplete: enableMarkAsComplete, onMarkAsComplete: onMarkAsComplete, status: activityStatus, onClickNextActivity: goToNextActivity, disableNextActivity: isMarkAsCompleteInFlight })) : (_jsx(NavigationBar, { type: props.activityType, module: props.analyticsScope, onBackNavigation: goBack, status: activityStatus, date: (response === null || response === void 0 ? void 0 : response.kind) === 'already_completed'
                    ? response.lastCompleted
                    : (response === null || response === void 0 ? void 0 : response.kind) === 'already_failed'
                        ? response.lastFailed
                        : new Date(), onClickNextActivity: goToNextActivity, disableNextActivity: isMarkAsCompleteInFlight })), response ? (response.kind === 'ok' ? (completionStatus === 'in_progress' ? (showPlayer(response)) : completionStatus === 'failed' ? (_jsx(ActivityStatusPage, { scope: props.analyticsScope, variant: "failed", contentType: props.activityType, contentId: response.activity.contentId, onClickReplay: () => fetch(true), canRetryAt: cooldownExpiresAt })) : completionStatus === 'passed' ? (_jsx(ActivityStatusPage, { scope: props.analyticsScope, variant: "success", contentType: props.activityType, contentId: response.activity.contentId, onClickBackToActivity: onClickBackToActivity })) : (assertNever(completionStatus, 'invalid activity status'))) : response.kind === 'already_completed' ? (showAlreadyCompleted(response)) : response.kind === 'already_failed' ? (_jsx(ActivityStatusPage, { scope: props.analyticsScope, variant: "already_failed", contentType: props.activityType, contentId: response.activity.contentId, onClickReplay: () => fetch(true), canRetryAt: cooldownExpiresAt })) : (assertNever(response, 'invalid state'))) : props.activityType === 'video' ? (_jsx(VideoPlayerBase, { video: undefined, analyticsScope: props.analyticsScope })) : props.activityType === 'challenge' ? (_jsx(ChallengePlayerWrapperSkeleton, {})) : (_jsx(_Fragment, {}))] }));
};
