const columns = [
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'email', headerName: 'Email', flex: 1 },
    { field: 'createdAt', headerName: 'Creation Date', flex: 1 },
    { field: 'team', headerName: 'Team', flex: 1 },
    { field: 'lastLogin', headerName: 'Last Login', flex: 1 },
    { field: 'status', headerName: 'Status', flex: 1 },
];
const columnVisibilityModel = {
    name: true,
    email: true,
    createdAt: true,
    team: true,
    lastLogin: true,
    status: true,
};
export const createGridProps = (total = 0) => {
    return {
        columns,
        columnVisibilityModel,
        initialState: {
            pagination: {
                paginationModel: { pageSize: 25, page: 0 },
                rowCount: total,
            },
        },
        disableColumnSelector: false,
        rowSelection: true,
        checkboxSelectionVisibleOnly: true,
        checkboxSelection: true,
        autoHeight: true,
    };
};
