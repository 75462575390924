import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ArrowBackRounded as ArrowBackIcon, ArrowForwardRounded as ArrowForwardIcon } from '@mui/icons-material';
import { Box, Stack, styled, Typography } from '@mui/material';
import { Button as WandaButton } from '@securecodewarrior/design-system-react/lib/wanda';
import { possibleActivityTypeOptions } from '../explore/FilterLogic';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../dateHelper';
import { StatusIcon } from '../common/icons/StatusIcon';
const Button = styled(WandaButton)(({ theme }) => ({
    paddingLeft: '24px',
    paddingRight: '24px',
    '& .MuiButton-startIcon': {
        fontSize: theme.typography.pxToRem(18), // override some default button styling, I don't want to mess with !important
        marginLeft: '-8px',
    },
    '& .MuiButton-endIcon': {
        fontSize: theme.typography.pxToRem(18), // override some default button styling, I don't want to mess with !important
        marginRight: '-8px',
    },
}));
const pageTitleTranslationKeys = {
    explore: 'pages.explore.title',
    cybermon: 'pages.cybermon.title',
    learn: 'pages.learn.course.details.activity.course',
    myQuests: 'pages.myQuests.title',
};
export const NavigationBar = (props) => {
    const { t } = useTranslation();
    const activityOptions = possibleActivityTypeOptions[props.type];
    return (_jsxs(Box, { flexDirection: {
            xs: 'column',
            md: 'row',
        }, sx: (theme) => ({
            borderBottom: `1px solid ${theme.palette.container.border.default}`,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: `${theme.spacing(3)} ${theme.spacing(10)}`,
            gap: theme.spacing(1),
            position: 'sticky',
            zIndex: 1,
            top: 0,
            backgroundColor: theme.palette.container.fill.body,
        }), children: [_jsx(Box, { flex: 1, children: _jsx(Button, { variant: "text", startIcon: _jsx(ArrowBackIcon, { fontSize: "inherit" }), onClick: props.onBackNavigation, children: t('components.organisms.navigationBar.backTo', { target: t(pageTitleTranslationKeys[props.module]) }) }) }), _jsx(Stack, { alignItems: "center", children: _jsxs(Box, { sx: (theme) => ({
                        display: 'flex',
                        gap: theme.spacing(1),
                        alignItems: 'center',
                        '& > .MuiSvgIcon-root': {
                            fontSize: theme.typography.pxToRem(24),
                            marginRight: theme.spacing(1),
                        },
                        '& > .activity-icon': {
                            marginLeft: theme.spacing(2.5),
                        },
                    }), children: [activityOptions.icon, _jsxs(Typography, { children: [_jsx("strong", { children: t(activityOptions.label) }), props.status === 'passed' ? (_jsxs(_Fragment, { children: [' ', t('common.completedOn'), " ", _jsx("strong", { children: formatDate(props.date) })] })) : props.status === 'failed' ? (_jsxs(_Fragment, { children: [' ', t('common.failedOn'), " ", _jsx("strong", { children: formatDate(props.date) })] })) : (props.status === 'in_progress' && _jsxs(_Fragment, { children: [" ", t('common.inProgress')] }))] }), props.status !== 'none' && _jsx(StatusIcon, { status: props.status, ml: 1.5 })] }) }), _jsxs(Box, { display: "flex", gap: 2, flex: 1, justifyContent: "flex-end", children: [(props.status === 'in_progress' || props.status === 'none') && props.onMarkAsComplete && (_jsx(Button, { variant: "contained", disabled: !props.enableMarkAsComplete, onClick: props.onMarkAsComplete, children: t('components.organisms.navigationBar.markAsComplete') })), props.onClickNextActivity && (_jsx(Button, { variant: props.status === 'passed' ? 'contained' : 'text', onClick: props.onClickNextActivity, endIcon: _jsx(ArrowForwardIcon, { fontSize: "inherit" }), disabled: props.disableNextActivity, children: t('components.organisms.navigationBar.nextActivity') }))] })] }));
};
