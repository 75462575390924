import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { List, ListItem, Stack, Typography } from '@mui/material';
import { Button } from '@securecodewarrior/design-system-react/lib/wanda';
import { Chip, ChipWithClose } from '../../../../common/components/atoms/Chip/Chip';
import { useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { AddRounded, EditRounded, OpenInFullRounded } from '@mui/icons-material';
export const ItemSelection = ({ selection, selectItemsKey, onItemRemove, onButtonClicked, disabled, }) => {
    const itemsContainer = useRef(null);
    const { t } = useTranslation();
    return (_jsx(Stack, { direction: "row", gap: 2, sx: (theme) => ({
            borderRadius: theme.shape.border.radius.md,
            border: `1px solid ${theme.palette.container.border.default}`,
            backgroundColor: disabled || selection.length === 0 ? theme.palette.container.fill.card2 : theme.palette.container.fill.card1,
        }), children: selection.length !== 0 ? (_jsxs(_Fragment, { children: [_jsxs(List, { "aria-label": t('questsAdmin.components.molecules.itemSelection.selectedItems'), sx: {
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 2,
                        py: 5,
                        flexWrap: 'wrap',
                        flexGrow: 1,
                        px: 3,
                    }, ref: itemsContainer, children: [selection.slice(0, selection.length === 10 ? 10 : 9).map((item) => (_jsx(ListItem, { sx: { width: 'fit-content', p: 0 }, children: disabled || !onItemRemove ? (_jsx(Chip, { children: _jsx(Typography, { variant: "captionBold", sx: (theme) => ({
                                        textOverflow: 'ellipsis',
                                        maxWidth: theme.typography.pxToRem(300),
                                        overflowX: 'hidden',
                                    }), children: item.label }) })) : (_jsx(ChipWithClose, { closeBtnAriaLabel: t('questsAdmin.components.molecules.itemSelection.removeItem', { item }), onDelete: () => onItemRemove(item), suffix: item.label })) }, item.key))), selection.length > 10 && (_jsx(ListItem, { sx: { width: 'fit-content', p: 0 }, children: _jsx(Chip, { children: _jsx(Typography, { variant: "captionBold", sx: (theme) => ({
                                        textOverflow: 'ellipsis',
                                        maxWidth: theme.typography.pxToRem(300),
                                        overflowX: 'hidden',
                                    }), children: _jsx(Trans, { i18nKey: "questsAdmin.components.molecules.itemSelection.hiddenCount", values: { hiddenCount: selection.length - 9 } }) }) }) }))] }), _jsx(Stack, { p: 3, direction: "row", gap: 2, alignItems: "flex-start", sx: (theme) => ({
                        minHeight: theme.typography.pxToRem(56),
                        borderLeft: `1px solid ${theme.palette.container.border.default}`,
                    }), children: disabled ? (_jsx(Button, { "aria-label": t('questsAdmin.components.molecules.itemSelection.viewAllItems'), onClick: onButtonClicked, sx: { p: 2 }, children: _jsx(OpenInFullRounded, {}) })) : (_jsx(Button, { "aria-label": t('questsAdmin.components.molecules.itemSelection.editSelection'), onClick: onButtonClicked, sx: { p: 2 }, children: _jsx(EditRounded, {}) })) })] })) : (selectItemsKey && (_jsx(Stack, { p: 3, alignItems: 'center', flexGrow: 1, children: _jsx(Button, { onClick: onButtonClicked, startIcon: _jsx(AddRounded, {}), sx: { width: 'fit-content' }, children: _jsx(Trans, { i18nKey: selectItemsKey }) }) }))) }));
};
