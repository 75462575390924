import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Divider, Typography, useTheme } from '@mui/material';
import { Stack } from '@mui/system';
import { BarChart } from '@securecodewarrior/design-system-react/lib/labs';
import { Button } from '@securecodewarrior/design-system-react/lib/wanda';
import { CardTemplate } from '../../../templates/CardTemplate';
import { DataLabel } from '../../molecules/DataLabel';
import { useDashboardAnalytics } from '../../../hooks/useDashboardAnalytics';
import { useTranslation } from 'react-i18next';
const engagementReportUrl = '#/reporting/onboarding-and-engagement/';
const formatTimeSpent = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    if (remainingMinutes === 0) {
        return `${hours}h`;
    }
    return `${hours}h ${remainingMinutes}m`;
};
export const LearnerEngagementActive = ({ monthlyUsage, totals }) => {
    const { t } = useTranslation('react_components', { keyPrefix: 'pages.administration.dashboard.learnerEngagement' });
    const theme = useTheme();
    const { emitEvent } = useDashboardAnalytics();
    const BARCHART_OFFSET = 10;
    const clickViewEngagementReport = (event) => {
        // Prevent the default link navigation
        event.preventDefault();
        emitEvent('clicked_view_engagement_report_button');
        window.location.href = engagementReportUrl;
    };
    return (_jsxs(CardTemplate, { children: [_jsxs(CardTemplate.Header, { children: [_jsx(Typography, { variant: "h5", flexWrap: "wrap", children: t('title') }), _jsx(Stack, { direction: "row", gap: 2, flexGrow: 1, justifyContent: "flex-end", flexShrink: 0, children: _jsx(Button, { variant: "text", href: engagementReportUrl, onClick: clickViewEngagementReport, children: t('viewEngagementReport') }) })] }), _jsxs(CardTemplate.Body, { children: [_jsx(Stack, { direction: "column", alignItems: "center", justifyContent: "space-between", gap: 4, children: _jsx(Box, { sx: { width: '100%', height: '100%' }, children: _jsx(BarChart, { series: [
                                    {
                                        label: t('graph.barHoverText'),
                                        data: monthlyUsage.map((data) => data.value),
                                        color: theme.palette.graph.data.d1,
                                        valueFormatter: (value) => `${value !== null && value !== void 0 ? value : 0} ${t('labels.activeLearners').toLowerCase()}`,
                                    },
                                ], xAxis: [
                                    {
                                        label: t('graph.xAxis'),
                                        data: monthlyUsage.map((data) => data.month),
                                        scaleType: 'band',
                                        tickPlacement: 'middle',
                                    },
                                ], yAxis: [
                                    {
                                        label: t('graph.yAxis'),
                                        max: monthlyUsage.every(({ value }) => value === 0)
                                            ? BARCHART_OFFSET
                                            : monthlyUsage.reduce((max, { value }) => Math.max(max, value) + BARCHART_OFFSET, 0),
                                    },
                                ], height: 212, isStacked: false, displayXTitle: true, displayYTitle: true }) }) }), _jsxs(Stack, { gap: 2, children: [_jsx(DataLabel, { label: t('labels.newLearners'), value: totals.newLearners }), _jsx(DataLabel, { label: t('labels.activeLearners'), value: totals.activeLearners }), _jsx(Divider, { sx: {
                                    borderColor: (theme) => theme.palette.container.border.default,
                                } }), _jsx(DataLabel, { label: t('labels.totalTimeSpent'), value: formatTimeSpent(totals.totalTimeSpent) })] })] })] }));
};
