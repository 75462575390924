import { courseActivityAnalytics } from '../../analytics.activities.course';
const scope = 'learn';
export const coursesCourseActivityAnalytics = (sendEvent, sessionUser) => courseActivityAnalytics('courses', 'Back to Courses Clicked', sessionUser, sendEvent);
export const learnAnalytics = (sendEvent) => ({
    onTopicCollapsedStateChanged: (categoryId, isNowCollapsed) => {
        sendEvent({ event: isNowCollapsed ? 'Topic closed' : 'Topic opened', scope, categoryId });
    },
    onFirstLanguageSelection: () => {
        sendEvent({ event: 'First Language selection open', scope });
    },
});
