import { QueryClient } from '@tanstack/react-query';
export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            staleTime: 1000 * 60 * 5, // 5 minutes
            retry: 3, // retry 3 times before giving up
            retryDelay: (attemptIndex) => Math.min(500 * Math.pow(2, attemptIndex), 30000),
        },
    },
});
