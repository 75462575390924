import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Typography, useTheme } from '@mui/material';
import { Stack } from '@mui/system';
import { Card, RadialChart } from '@securecodewarrior/design-system-react/lib/labs';
import { Button } from '@securecodewarrior/design-system-react/lib/wanda';
import { CardTemplate } from '../../../templates/CardTemplate';
import { getOrdinal } from '../../../../utils/getOrdinal';
import { useDashboardAnalytics } from '../../../hooks/useDashboardAnalytics';
import { useTranslation, Trans } from 'react-i18next';
import { usePlatformContext } from '../../../../platformContext';
import { platformLocaleToBcp47 } from '../../../../../utils/platformLocaleToBcp47';
const trustScoreReportUrl = '#/reporting/trust-score/';
export const TrustScoreActive = ({ data }) => {
    var _a, _b, _c;
    const { palette } = useTheme();
    const { emitEvent } = useDashboardAnalytics();
    const { t } = useTranslation('react_components', { keyPrefix: 'pages.administration.dashboard.trustScore' });
    const ctx = usePlatformContext();
    const clickViewTrustScoreReport = (event) => {
        // Prevent the default link navigation
        event.preventDefault();
        emitEvent('clicked_trust_score_report_button');
        window.location.href = trustScoreReportUrl;
    };
    const chartData = {
        value: data.trustScore,
        type: 'fraction',
        total: 1000,
        animate: true,
        name: 'value',
        fill: palette.graph.data.d1,
    };
    return (_jsxs(CardTemplate, { children: [_jsxs(CardTemplate.Header, { children: [_jsx(Typography, { variant: "h5", flexWrap: "wrap", children: t('title') }), _jsx(Button, { variant: "text", href: trustScoreReportUrl, onClick: clickViewTrustScoreReport, children: t('viewTrustScoreReport') })] }), _jsxs(CardTemplate.Body, { children: [_jsxs(Stack, { alignItems: "center", gap: 4, children: [_jsx(RadialChart, { data: chartData, dataKey: "value", domain: [0, 1000] }), _jsx(Card, { level: 2, sx: { padding: (theme) => theme.spacing(2, 3) }, children: _jsx(Typography, { textAlign: "center", children: _jsx(Trans, { i18nKey: "pages.administration.dashboard.trustScore.globalRank", values: {
                                            percentile: platformLocaleToBcp47((_c = (_b = (_a = ctx.sessionUser) === null || _a === void 0 ? void 0 : _a.properties) === null || _b === void 0 ? void 0 : _b.profile) === null || _c === void 0 ? void 0 : _c.subtitlesLanguage) === 'en-US'
                                                ? getOrdinal(data.percentile)
                                                : data.percentile,
                                        } }) }) })] }), _jsx(Stack, { gap: 4, children: data.recommendation ? (_jsxs(_Fragment, { children: [_jsx(Typography, { variant: "h6", children: t('topRecommendation') }), _jsx(Typography, { children: data.recommendation })] })) : (_jsxs(_Fragment, { children: [_jsx(Typography, { variant: "h6", children: t('noRecommendationTitle') }), _jsx(Typography, { children: t('noRecommendationDescription1') }), _jsx(Typography, { children: t('noRecommendationDescription2') })] })) })] })] }));
};
