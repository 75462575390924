import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ActivityRow } from '../../components/ActivityRow';
import { Trans, useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
export const TopicSection = ({ kind, activities, onStartActivity, highlightedActivityId, backupLanguage, }) => {
    const { t } = useTranslation();
    return (_jsxs(Stack, { gap: 5, children: [kind === 'quiz' && (_jsx(Typography, { variant: "caption", sx: (theme) => ({
                    '& > strong': { color: theme.palette.text.soft },
                    color: theme.palette.text.body,
                }), children: _jsx(Trans, { i18nKey: "pages.quests.quizDescription" }) })), _jsx(Stack, { gap: 2, role: "list", "aria-label": t(`pages.quests.${kind}`), children: activities.map((a) => (_jsx(ActivityRow, { activity: a, highlighted: !!highlightedActivityId && highlightedActivityId === a.id, onClick: () => onStartActivity(a), kind: kind, backupLanguage: backupLanguage }, `activity_${a.id}`))) })] }));
};
