import { jsx as _jsx } from "react/jsx-runtime";
import { coursesCourseActivityAnalytics } from './learn.analytics';
import { usePlatformContext } from '../../platformContext';
import { useApiClient } from '../../ApiContext';
import { useParams } from 'react-router-dom';
import { CourseActivity } from './activities/CourseActivity';
export const CourseActivityFromRouteParams = () => {
    const { courseId, chapterNr: cNr, topicNr: tNr, activityType, activityNr: aNr } = useParams();
    const { trpc } = useApiClient();
    const chapterNr = parseInt(cNr !== null && cNr !== void 0 ? cNr : '');
    const topicNr = parseInt(tNr !== null && tNr !== void 0 ? tNr : '');
    const activityNr = parseInt(aNr !== null && aNr !== void 0 ? aNr : '');
    if (!courseId ||
        (activityType !== 'video' &&
            activityType !== 'guideline' &&
            activityType !== 'challenge' &&
            activityType !== 'coding_lab' &&
            activityType !== 'mission' &&
            activityType !== 'walkthrough') ||
        isNaN(chapterNr) ||
        isNaN(topicNr) ||
        isNaN(activityNr)) {
        throw new Error('Invalid parameters');
    }
    const { logAnalyticsEvent, sessionUser } = usePlatformContext();
    const analytics = coursesCourseActivityAnalytics(logAnalyticsEvent, sessionUser);
    const startOrContinueFn = (redoIfCompleted) => {
        const input = { questId: courseId, chapterNr, topicNr, activityType, activityNr, redoIfCompleted };
        return trpc.quests.startOrContinueActivity.mutate(input);
    };
    const getNextActivityFrontendPath = (n) => {
        return `/learn/course/${courseId}/chapter/${n.chapterNr}/topic/${n.topicNr}/${n.activityType}/${n.activityNr}`;
    };
    const backNavigationData = {
        highlightedActivity: { chapterNr, topicNr, activityType, activityNr },
    };
    return (_jsx(CourseActivity, { courseId: courseId, activityType: activityType, analytics: analytics, analyticsScope: "learn", returnPath: `/learn/course/${courseId}`, backNavigationData: backNavigationData, startOrContinueFn: startOrContinueFn, getNextActivityFrontendPath: getNextActivityFrontendPath }));
};
